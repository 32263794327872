.carousel {
    min-width: 100%;
    position: relative;
    img {
        min-height: 300px;
        min-width: 100%;
    }
    .carousel-caption {
        bottom: initial;
        left: 10%;
        max-width: 100%;
        right: 10%;
        text-align: center;
        top: 50%;
        .transform(translateY(-50%));
        @media @tablet {
            top: 60%;
            .transform(translateY(-60%));
        }
        @media @mobile {
            top: 80%;
            .transform(translateY(-75%));
        }
        h2 {
            font-size: 40px;
            font-weight: 400;
            margin: 10px 0;
            padding: 18px;
            text-shadow: none;
            @media @desktop {
                font-size: 30px;
            }
            @media @tablet {
                font-size: 25px;
            }
            @media @mobile {
                font-size: 20px;
            }
            strong {
                display: block;
                font-size: 70px;
                font-weight: 900;
                @media @desktop {
                    font-size: 40px;
                }
                @media @tablet {
                    font-size: 30px;
                }
                @media @mobile {
                    font-size: 20px;
                }
            }
        }
        .slide-buttons {
            margin: 10px 0;
            text-align: center;
            .btn {
                border: 0;
                margin-right: 6px;
                padding: 10px 28px;
                &.btn-default {
                    color: @dark;
                    margin-right: 0;
                    &:hover {
                        color: @light;
                    }
                }
            }
        }
    }
}
.item {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
}
