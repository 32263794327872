@import "../../backend/less/custom/inline-translation.less";


[aria-describedby="translate-manager-div"] {

  .ui-dialog-titlebar-close {
    //visibility: hidden;
    margin-top: -25px;
    font-size: 25px;
    margin-right: 8px;

    &:after {
      visibility: visible;
      content: "×";
    }
  }

  .ui-dialog-titlebar-close {
    background: none !important;
    border: none !important;
  }

}


#toggle-translate {
  display: block !important;
  position: fixed !important;
  padding: inherit !important;
  border: inherit !important;
  background: inherit !important;
  color: #03a9f3 !important;
  left: 15px;
  bottom: 15px;
  z-index: 1100;
  .btn {
    width: auto;
  }
}