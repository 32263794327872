article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
    display: block;
    font-size: @font-size-base;
    color: @dark;
}
section{
    padding: 60px 0;
}
audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline;
}
audio:not([controls]) {
    display: none;
}
[hidden],
template {
    display: none;
}
a {
    background-color: transparent;
    color: @link-color;
    &:active,
    &:hover {
        outline: 0;
        color: @link-hover-color;
        text-decoration: @link-hover-decoration;
    }
}
abbr[title] {
    border-bottom: 1px dotted;
}
b,
strong {
    font-weight: bold;
}
dfn {
    font-style: italic;
}
h1 {
    font-size: @font-size-h1;
}
mark {
    background: @brand-primary;
    color: @light;
}
small {
    font-size: 65%;
}
sub,
sup {
    font-size: 65%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sup {
    top: -0.5em;
}
sub {
    bottom: -0.25em;
}
img {
    border: 0;
}
svg:not(:root) {
    overflow: hidden;
}
hr {
    box-sizing: content-box;
    height: 0;
}
pre {
    overflow: auto;
}
code,
kbd,
pre,
samp {
    font-family: @font-family-sans-serif;
    font-size: 1em;
}
button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}
.btn {
    font-size: @font-size-large;
    padding: 10px 15px;
    text-transform: uppercase;
    @media @mobile {
        font-size: @font-size-base;
        padding: 10px;
        width: 120px;
    }
}
.btn:active,
.btn.active {
    .box-shadow(none);
}
.btn-default {
    &:hover {
        background-color: @dark;
        border-color: @dark;
        color: @light;
    }
}
button {
    .btn();
    overflow: visible;
}
button,
select {
    text-transform: none;
    &:focus {
        outline: none;
    }
}
button,
html {
    input[type="button"],
    input[type="reset"],
    input[type="submit"] {
        cursor: pointer;
        -webkit-appearance: button;
    }
}
button[disabled],
html {
    input[disabled] {
        cursor: default;
    }
}
button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}
input {
    line-height: normal;
}
fieldset {
    border: 1px solid @brand-primary;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}
legend {
    border: 0;
    padding: 0;
}
textarea {
    overflow: auto;
}
optgroup {
    font-weight: bold;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
td,
th {
    padding: 0;
}
.entry-content figure img {
    padding: 10px 0;
}
.form-control {
    .appearance;
}
.pp_nav {
    @media @mobcustom {
        display: none !important;
    }
}

/* Homepage Section Divider */
.mask {
    border-bottom-color: transparent !important;
    border-left: 50vw solid @light;
    border-right: 50vw solid @light;
    border-style: solid;
    border-top-color: transparent !important;
    height: 0;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    .transform(scale(1.015));
    transform-style: preserve-3d;
    width: 0;
    z-index: 10;
    @media @desktop1024 {
        border-left-width: 49.3vw;
        border-right-width: 49.3vw;
    }
    &.mask-top {
        border-bottom-width: 70px;
        border-top-width: 0;
        top: 0;
        @media @mobile {
            border-bottom-width: 40px;
        }
    }
    &.mask-bottom {
        border-bottom-width: 0;
        border-top-width: 70px;
        bottom: 0;
        @media @mobile {
            border-top-width: 40px;
        }
    }
    &.white {
        border-color: @light;
    }
    &.light-gray {
        border-color: #fafafa;
    }
}

/* SVG */
.base-shape {
    padding: 0;
}
.hm-shape-outer {
    position: relative;
    .hm-shape-inner {
        left: 0;
        margin-left: 50px;
        margin-right: 50px;
        position: absolute;
        right: 0;
        top: -219px;
        @media @desktop1299 {
            top: -168px;
        }
        @media @desktop {
            top: -138px;
        }
        @media @tablet {
            margin-left: 0;
            margin-right: 0;
            top: -120px;
        }
        .hm-shape-btm {
            background-color: @light;
            margin-top: -6px;
            min-height: 140px;
        }
    }
}
.pg-shape-outer {
    position: relative;
    .pg-shape-inner {
        left: 0;
        margin-left: 50px;
        margin-right: 50px;
        position: absolute;
        right: 0;
        top: -158px;
        @media @desktop {
            top: -138px;
        }
        @media @tablet {
            margin-left: 0;
            margin-right: 0;
            top: -153px;
        }
        .pg-shape-btm {
            background-color: @light;
            margin-top: -6px;
            min-height: 61px;
            @media @desktop {
                min-height: 39px;
            }
            @media @tablet {
                min-height: 55px;
            }
        }
    }
}
.polygon-fill {
    fill: @light;
}

/* Services List */
.services {
    padding: 0 0 95px;
    position: relative;
    @media @desktop {
        padding: 60px 0 95px;
    }
    @media @tablet {
        padding: 50px 0 15px;
    }
    @media @mobile {
        padding: 50px 0 40px;
    }
    .col-sm-6 {
        &:nth-child(3) {
            @media @tablet {
                margin-left: 25%;
            }
        }
    }
    .items {
        margin-top: -35px;
        overflow: hidden;
        padding: 35px 0 10px;
        @media @desktop1024 {
            cursor: pointer;
        }
        @media @tablet {
            margin-bottom: 25px;
        }
        @media @mobile {
            margin: 0 auto;
            width: 260px;
        }
        .shape {
            background-color: @light;
            height: 365px;
            margin: 0 auto;
            position: relative;
            text-align: center;
            width: 100%;
            @media @mobile {
                height: 375px;
            }
            .shape-inner {
                background: inherit;
                display: block;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                .transition;
                width: 100%;
                z-index: 2;
            }
            i {
                background-image: url(images/services-home-sprite.png);
                background-position: 500px 500px;
                background-repeat: no-repeat;
                display: inline-block;
                height: 87px;
                margin: 14px 0 2px;
                width: 104px;
                &.code {
                    background-position: 0 0;
                }
                &.design {
                    background-position: -104px 0;
                }
                &.responsive {
                    background-position: -208px 0;
                }
            }
            h4 {
                font-size: @font-size-h3;
                font-weight: 300;
                margin: 20px 0 17px;
                @media @mobile {
                    font-size: 15px;
                }
                strong {
                    display: block;
                    font-size: 30px;
                    @media @desktop {
                        font-size: 25px;
                    }
                    @media @mobile {
                        font-size: 20px;
                    }
                }
            }
            p {
                color: @dark;
                line-height: 1.85;
                margin: 0;
            }
            hr {
                background-color: @light;
                bottom: 41px;
                left: 0;
                margin: 0;
                position: absolute;
                width: 100%;
                z-index: 20;
                @media @mobile {
                    bottom: 22px;
                }
            }
            &::before {
                content: "";
                height: 220px;
                left: 45px;
                position: absolute;
                top: -110px;
                .transform(scaleY(0.2) rotate(-45deg));
                width: 100%;
                z-index: 1;
            }
        }
        &:hover {
            .shape {
                .box-shadow(0 1px 8px #ccc);
                padding: 0 23px;
                position: relative;
                width: 310px;
                @media @desktop {
                    width: 275px;
                }
                @media @mobile {
                    width: 245px;
                }
                i {
                    &.code {
                        background-position: 0 -87px;
                    }
                    &.design {
                        background-position: -104px -87px;
                    }
                    &.responsive {
                        background-position: -208px -87px;
                    }
                }
                p {
                    color: #000;
                }
                &::before {
                    background-color: inherit;
                    .box-shadow(0 0 15px #ccc);
                    width: 220px;
                    @media @desktop {
                        height: 195px;
                        left: 40px;
                        top: -98px;
                        width: 195px;
                    }
                    @media @mobile {
                        height: 175px;
                        left: 35px;
                        top: -88px;
                        width: 175px;
                    }
                }
            }
            &::after {
                border: 1px solid @blue;
                border-color: @blue;
                content: "";
                height: 295px;
                left: 0;
                position: absolute;
                top: 29px;
                width: 100%;
                z-index: 10;
                @media @tabcustom {
                    left: 0;
                    margin: 0 auto;
                    right: 0;
                }
                @media @mobile {
                    height: 324px;
                    top: 64px;
                    width: 290px;
                }
            }
            &.border-yellow {
               &::after {
                    border-color: @yellow;
                }
            }
            &.border-blue {
               &::after {
                    border-color: @blue;
                }
            }
            &.border-green {
               &::after {
                    border-color: @green;
                }
            }
            .shape-inner {
                padding: 15px 25px 0;
            }
        }
    }
}
.hm-company-info {
    overflow: hidden;
    .hm-company-detail {
        margin-top: 40px;
        @media @desktop {
            margin-top: 0;
        }
        @media @tabcustom {
            margin-top: 0;
            text-align: center;
        }
        h3 {
            left: 0;
            .transform(translateX(0));
        }
        span {
            display: block;
        }
        p {
            font-size: @font-size-large;
            letter-spacing: 1px;
            line-height: 30px;
            padding: 41px 0 21px;
        }
        .btn {
            color: @light;
            margin-right: 6px;
            width: 140px;
            &.btn-default {
                color: @dark;
                margin-right: 0;
                &:hover {
                    color: @light;
                }
            }
        }
    }
    .col-md-8 {
        & > .row {
            @media @tablet {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}

/* Skills */
.skills-section {
    float: left;
    margin: 0;
    width: 100%;
    @media @tablet {
        margin-top: 100px;
    }
    @media @mobile {
        margin-top: 100px;
    }
    .row {
        @media @mobile {
            margin-left: 0;
            margin-right: 0;
        }
    }
    figure {
        margin-top: -45px;
        position: relative;
        z-index: 1;
        img {
            width: 100%;
        }
    }
    .work-progress {
        .bg-blue();
        margin-left: -3px;
        min-height: 521px;
        padding-top: 143px;
        @media @desktop {
            min-height: 424px;
            padding-top: 94px;
        }
        @media @tablet {
            min-height: 478px;
            padding-top: 121px;
        }
        @media @mobile {
            float: left;
            margin-left: 0;
            min-height: 0;
            padding: 57px 15px 20px;
            width: 100%;
        }
        &::before {
            .bg-blue();
            content: "";
            height: 521px;
            position: absolute;
            right: -1000%;
            top: 0;
            width: 1000%;
            @media @desktop {
                height: 424px;
            }
            @media @tablet {
                height: 478px;
            }
        }
        .item {
            float: left;
            transform-style: flat;
            -webkit-transform-style: flat;
            width: 100%;
        }
        .icon {
            float: left;
            font-size: 30px;
            margin-top: -18px;
            padding: 0 7px 0 23px;
            text-align: center;
            width: 80px;
            @media @mobile {
                clear: left;
                padding-left: 0;
                width: 45px;
            }
            i {
                font-size: 20px;
            }
            span {
                float: right;
            }
        }
        .progress-info {
            float: left;
            margin-bottom: 30px;
            width: 305px;
            @media @desktop {
                width: 205px;
            }
            @media @tablet {
                width: 260px;
            }
            @media @mobile {
                width: 70%;
            }
            label {
                font-weight: 400;
                margin-bottom: 0;
                .text-transform-uppercase;
            }
        }
        .progress {
            background-color: transparent;
            border-bottom: 1px solid @light;
            .box-shadow(none);
            height: 5px;
            margin-bottom: 0;
            margin-top: -2px;
            overflow:visible;
        }
        .progress-bar {
            .box-shadow(none);
            display: block;
            height: 5px;
            .transition(@prop: all, @time: 1.5s, @ease: ease-in-out);
        }
        .tooltip {
            float: right;
            left: 34px !important;
            margin-top: 0;
            padding: 0;
            position: relative;
            top: -29px !important;
            .tooltip-inner {
                background-color: @light;
                .border-radius(0);
                color: #000;
                font-size: 12px;
                padding: 6px 5px;
            }
            .tooltip-arrow {
                border: 0;
            }
        }
        .tooltip.in {
            .opacity(1);
        }
    }
}

/* Features */
.features {
    padding: 50px 0 72px;
    p {
        margin-bottom: 35px;
        line-height: 2.1;
    }
    .features-desc {
        p {
            font-size: @font-size-large;
            margin-bottom: 42px;
        }
    }
    .features-list {
        margin-top: 7px;
        .col-md-4 {
            &:nth-child(4){
                clear: left;
            }
        }
        h4 {
            font-size: @font-size-h3;
            font-weight: 400;
            margin: 0 0 16px;
        }
        .item {
            margin: 0 0 38px;
            @media @desktop1024 {
                cursor: pointer;
            }
            @media @tabcustom {
                float: left;
                width: 100%;
            }
            @media @mobile {
                text-align: center;
            }
            .icon {
                .border-radius(50%);
                float: left;
                padding: 5px;
                margin: 3px 30px 0 0;
                .transition;
                @media @mobile {
                    display: inline-block;
                    float: none;
                    margin: 3px 0 22px;
                }
                .icon-circle {
                    .border-radius(50%);
                    height: 60px;
                    text-align: center;
                    width: 60px;
                    i {
                        .c-light();
                        font-size: 30px;
                        padding: 15px 0;
                    }
                }
            }
            .caption {
                overflow: hidden;
                @media @mobile {
                    width: 100%;
                }
                p {
                    margin-bottom: 0;
                }
            }
            &:hover {
                .icon {
                    border-color: transparent;
                    .box-shadow(0 1px 8px #ccc);
                    .rotation(360deg);
                }
            }
        }
    }
}

/* Testimonials */
.testimonials {
    background-color: #272727;
    padding: 110px 0 220px;
    position: relative;
    h3 {
        .c-light();
        &::after,
        &::before {
            border-color: @light;
        }
    }
    p {
        .c-light();
        margin-bottom: 50px;
    }
    .carousel {
        figure {
            margin-bottom: 42px;
            @media @mobile {
                margin-bottom: 46px;
            }
            img {
                border: 5px solid #2b2b2b;
                .border-radius(50%);
                height: 70px;
                margin: -28px 6px 0 0;
                width: 70px;
            }
            .client-name {
                color: @blue;
                font-weight: 700;
                padding-bottom: 1px;
            }
            .designation {
                color: @light;
                font-size: 12px;
                font-weight: 300;
            }
        }
        figcaption {
            display: inline-block;
            padding-top: 19px;
            text-align: left;
        }
        p {
            font-size: 16px;
            font-style: italic;
            line-height: 1.8;
            margin-bottom: 0;
        }
    }
    .carousel-control {
        border: 1px solid @light;
        .border-radius(50%);
        font-size: 23px;
        height: 40px;
        margin-top: -20px;
        .opacity(1);
        padding: 1px 0;
        text-shadow: none;
        top: 50%;
        width: 40px;
        @media @desktop1024 {
            display: none;
        }
        &.left,
        &.right {
            background-image: none;
        }
        i {
            color: @light;
            .transition;
        }
        &:hover {
            background-color: @blue;
            border: 1px solid @blue;
            i {
                color: @light;
            }
        }
    }
}

/* Team */
.team {
    padding: 110px 0 50px;
    @media @tabcustom {
        padding: 110px 0 0;
    }
    .col-sm-6 {
        &:nth-child(3){
            @media @tablet {
                margin-left: 25%;
            }
        }
    }
    p {
        margin-bottom: 49px;
    }
    figure {
        cursor: pointer;
        display: block;
        height: 100%;
        margin: 0;
        padding: 0;
        position: relative;
        text-align: center;
        @media @tabcustom {
            margin: 0 auto 50px;
        }
        @media @tablet {
            width: 345px;
        }
        @media @mobile {
            width: 290px;
        }
        img {
            .transition;
            width: 100%;
        }
        .info {
            border: 1px solid #e5e5e5;
            border-top: 0;
            padding: 29px 10px 19px;
        }
        .name {
            color: @dark;
            font-size: @font-size-large;
            text-transform: none;
        }
        .designation {
            color: @blue;
            font-size: 12px;
            font-weight: 300;
        }
        &:hover {
            img {
                padding-bottom: 36px;
                width: 90%;
                @media @desktop {
                    width: 87.5%;
                }
                @media @tablet {
                    padding-bottom: 34px;
                }
                @media @mobile {
                    width: 87.5%;
                }
            }
            figcaption {
                height: 58%;
                .opacity(1);
                top: 42%;
                @media @desktop {
                    height: 75%;
                    top: 25.3%;
                }
                @media @tablet {
                    height: 64%;
                    top: 36%;
                }
                @media @mobile {
                    height: 75%;
                    top: 25.3%;
                }
            }
        }
    }
    figcaption {
        background: @blue;
        .box-shadow(0 1px 7px rgba(0, 0, 0, .15));
        color: @light;
        height: 53%;
        .opacity(0);
        position: absolute;
        top: 47%;
        .transition;
        width: 100%;
        @media @desktop {
            height: 71%;
            top: 29%;
        }
        @media @tablet {
            height: 60%;
            top: 40%;
        }
        @media @mobile {
            height: 71%;
            top: 29%;
        }
        .shape-inner {
            background: inherit;
            display: block;
            height: 100%;
            left: 0;
            padding: 23px 20px 16px;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 2;
        }
        &::before {
            background-color: inherit;
            .box-shadow(0 1px 7px rgba(0, 0, 0, .15));
            content: "";
            height: 255px;
            left: 53px;
            position: absolute;
            top: -128px;
            .transform(scaleY(0.18) rotate(-45deg));
            width: 255px;
            z-index: 1;
            @media @desktop {
                height: 208px;
                left: 43px;
                top: -104px;
                width: 208px;
            }
            @media @tablet {
                height: 245px;
                left: 50px;
                top: -123px;
                width: 245px;
            }
            @media @mobile {
                height: 206px;
                left: 42px;
                top: -103px;
                width: 206px;
            }
        }
        .member-info {
            float: left;
            text-align: left;
            width: 50%;
            @media @mobile, @tablet, @desktop {
                width: 100%;
            }
        }
        .name {
            color: @light;
            .text-transform-uppercase;
        }
        .designation {
            color: @light;
        }
        ul {
            float: right;
            list-style: none;
            margin: 5px 0 27px;
            padding: 0;
            text-align: right;
            width: 50%;
            @media @mobile, @tablet, @desktop {
                float: left;
                margin: 16px 0 14px;
                text-align: left;
                width: 100%;
            }
            li {
                display: inline-block;
                margin-left: 1px;
                a {
                    background-color: transparent;
                    border: 1px solid @light;
                    .border-radius(50%);
                    display: block;
                    height: 36px;
                    padding: 8px 0;
                    text-align: center;
                    .transition;
                    width: 36px;
                    i {
                        color: @light;
                        font-size: 18px;
                        .transition;
                    }
                    &:hover {
                        background-color: @light;
                        i {
                            color: @blue;
                        }
                    }
                }
            }
        }
        .progress-info {
            float: left;
            margin-bottom: 13px;
            position: relative;
            text-align: left;
            width: 87.5%;
            label {
                font-size: 12px;
                font-weight: 300;
                margin-bottom: 3px;
                .text-transform-uppercase;
            }
        }
        .progress {
            background-color: transparent;
            border: 1px solid @light;
            .border-radius(3px);
            .box-shadow(none);
            height: 10px;
            margin-bottom: 0;
            margin-top: -2px;
            overflow:visible;
        }
        .progress-bar {
            .bg-light();
            .border-radius(2px);
            .box-shadow(none);
            display: block;
            height: 8px;
            .transition(@prop: all, @time: 1.5s, @ease: ease-in-out);
        }
        .tooltip {
            left: 100% !important;
            margin-top: 0;
            padding: 0;
            position: absolute;
            top: 10px !important;
            .tooltip-inner {
                background-color: transparent;
                .border-radius(0);
                color: @light;
                font-size: 10px;
                padding: 6px 15px;
            }
            .tooltip-arrow {
                border: 0;
            }
        }
        .tooltip.in {
            .opacity(1);
        }
    }
}

/* Portfolio */
.hm-portfolio {
    .portfolio-list {
        float: left;
        margin: 30px 0;
        width: 100%;
        .portfolio-item {
            float: left;
            width: 20%;
            @media @tablet {
                width: 50%;
            }
            @media @mobile {
                width: 100%;
            }
        }
    }
    figure {
        cursor: pointer;
        display: block;
        height: 100%;
        margin: 0;
        padding: 0;
        position: relative;
        overflow: hidden;
        img {
            width: 100%;
        }
        &:hover {
            figcaption {
                .opacity(1);
                top: 0;
                h5 {
                    .opacity(1);
                    top: 0;
                    .transition-delay(.5s);
                }
                span {
                    .opacity(1);
                    top: 0;
                    .transition-delay(.4s);
                }
                ul {
                    li {
                       &:first-child {
                            right: 0;
                            .transition-delay(.4s);
                        }
                        &:nth-child(2) {
                            .opacity(1);
                            top: 0;
                            .transition-delay(.4s);
                        }
                        &:last-child {
                            left: 0;
                            .transition-delay(.4s);
                        }
                    }
                }
            }
        }
    }
    figcaption {
        background: rgba(0, 0, 0, 0.5);
        color: @light;
        height: 100%;
        .opacity(0);
        padding: 15px;
        position: absolute;
        top: 250px;
        text-align: center;
        .transition(@prop: all, @time: .4s, @ease: ease);
        .transition-delay(0.1s);
        width: 100%;
        .info-block {
            left: 0;
            margin-top: -2px;
            position: absolute;
            right: 0;
            top: 50%;
            .transform(translateY(-50%));
        }
        h5 {
            color: @blue;
            margin: 0 0 2px;
            .opacity(0);
            position: relative;
            top: -100px;
            text-transform: none;
            .transition(@prop: all, @time: .4s, @ease: ease);
            .transition-delay(.2s);
        }
        span {
            color: @light;
            .opacity(0);
            position: relative;
            top: -100px;
            .transition(@prop: all, @time: .4s, @ease: ease);
            .transition-delay(.2s);
        }
        ul {
            list-style: none;
            margin: 26px 0 0;
            padding: 0;
            width: 100%;
            li {
                display: inline-block;
                margin: 0 3px;
                position: relative;
                .transition(@prop: all, @time: .4s, @ease: ease);
                a {
                    background-color: transparent;
                    border: 1px solid @light;
                    .border-radius(50%);
                    display: block;
                    height: 46px;
                    padding: 12px 0;
                    width: 46px;
                    i {
                        color: @light;
                        font-size: 20px;
                    }
                    &:hover {
                        background-color: @blue;
                        border-color: @blue;
                    }
                }
                &:first-child {
                    right: 100%;
                    .transition-delay(.3s);
                }
                &:nth-child(2) {
                    .opacity(0);
                    top: 100px;
                    .transition-delay(.3s);
                }
                &:last-child {
                    left: 100%;
                    .transition-delay(.3s);
                }
            }
        }
    }
    .btn {
        .border-radius(25px);
        font-size: 18px;
        padding: 11px 15px 12px;
        width: 200px;
    }
}

/* Latest Blogs */
.latest-blog {
    padding: 50px 0 215px;
    position: relative;
    @media @mobile {
        padding: 50px 0 185px;
    }
    p {
        margin-bottom: 48px;
    }
    .articles-list {
        min-height: 710px;
        position: relative;
        @media @desktop {
            min-height: 582px;
        }
        @media @tablet {
            min-height: 1749px;
        }
        @media @mobile {
            min-height: 0;
        }
        article {
            cursor: pointer;
            position: absolute;
            width: 262px;
            @media @desktop {
                width: 213px;
            }
            @media @tablet {
                width: 345px;
            }
            @media @mobile {
                margin: 0 auto 30px;
                position: relative;
                width: 290px;
            }
            .entry-title {
                color: @light;
            }
            .entry-meta {
                color: @light;
                font-size: 10px;
                font-weight: 300;
                .text-transform-uppercase;
                a {
                    color: @light;
                    &:hover {
                       color: @dark;
                    }
                }
            }
            .entry-content {
                margin-top: 6px;
                p {
                    color: @light;
                    line-height: 1.8;
                    margin-bottom: 10px;
                }
            }
            .btn {
                background-color: @light;
                color: @dark;
                &:hover {
                    background-color: @dark;
                    color: @light;
                }
            }
            .shape {
                background-color: @blue;
                height: 50%;
                .opacity(0);
                position: absolute;
                top: 50%;
                .transition(@prop: all, @time: .4s, @ease: ease);
                width: 100%;
                z-index: 20;
                .shape-inner {
                    background: @blue;
                    display: block;
                    height: 100%;
                    left: 0;
                    padding: 4px 15px 10px;
                    position: absolute;
                    text-align: center;
                    top: 0;
                    .transition;
                    width: 100%;
                    z-index: 20;
                    @media @desktop {
                        top: 1px;
                    }
                }
                &::before {
                    background-color: inherit;
                    content: "";
                    height: 186px;
                    left: 38px;
                    position: absolute;
                    top: -93px;
                    .transform(scaleY(0.18) rotate(-45deg));
                    width: 186px;
                    z-index: 10;
                    @media @desktop {
                        height: 152px;
                        left: 31px;
                        top: -76px;
                        width: 151px;
                    }
                    @media @tablet {
                        height: 246px;
                        left: 50px;
                        top: -123px;
                        width: 245px;
                    }
                    @media @mobile {
                        height: 206px;
                        left: 42px;
                        top: -103px;
                        width: 206px;
                    }
                }
            }
            &:hover {
                figure {
                    &::before {
                        .opacity(.8);
                    }
                }
                .date {
                    background-color: @blue;
                    left: 50%;
                    margin-left: -35px;
                }
                .shape {
                    background-color: @blue;
                    .box-shadow(0 1px 7px rgba(0, 0, 0, .15));
                    height: 55%;
                    .opacity(1);
                    top: 45%;
                }
            }
            &:first-child {
                left: 0;
                top: 0;
            }
            &:nth-child(2) {
                bottom: 0;
                left: 0;
            }
            &:nth-child(5) {
                top: 0;
                right: 0;
            }
            &:last-child {
                bottom: 0;
                right: 0;
            }
            &:nth-child(3) {
                cursor: pointer;
                left: 292px;
                top: 0;
                width: 555px;
                @media @desktop {
                    left: 243px;
                    width: 455px;
                }
                @media @tablet {
                    left: 0;
                    top: 478px;
                    width: 720px;
                }
                @media @mobile {
                    left: 0;
                    margin: 0 auto 30px;
                    width: 290px;
                }
                .entry-header {
                    float: left;
                    width: 100%;
                }
                .blog-info {
                    float: left;
                    width: 50%;
                }
                .blog-share {
                    float: left;
                    margin-top: 12px;
                    text-align: right;
                    width: 50%;
                    a {
                        border: 1px solid @dark;
                        .border-radius(16px);
                        color: @dark;
                        display: inline-block;
                        font-size: 12px;
                        font-weight: 300;
                        height: 34px;
                        margin-left: 2px;
                        padding: 7px 0;
                        text-align: center;
                        width: 59px;
                        i {
                            color: @dark;
                            .transition;
                        }
                    }
                }
                .entry-title {
                    color: @dark;
                    font-size: 20px;
                    .transition;
                }
                .entry-meta {
                    color: @dark;
                    font-size: 10px;
                    font-weight: 300;
                    .text-transform-uppercase;
                    .transition;
                    a {
                        color: @dark;
                        &:hover {
                           color: @dark;
                        }
                    }
                }
                .entry-content {
                    margin: 24px 0 0;
                    @media @desktop {
                        margin: 12px 0 0;
                    }
                    p {
                        color: @dark;
                        margin-bottom: 26px;
                        .transition;
                        @media @desktop {
                            line-height: 1.5;
                            margin-bottom: 15px;
                        }
                    }
                }
                .btn {
                    background-color: @blue;
                    color: @light;
                }
                .shape {
                    background-color: transparent;
                    border: 1px solid #e5e5e5;
                    border-top: 0;
                    height: 105.5%;
                    margin-top: -19px;
                    .opacity(1);
                    position: absolute;
                    top: 105.5%;
                    .transition(@prop: all, @time: .4s, @ease: ease);
                    width: 100%;
                    @media @desktop {
                        margin-top: -15px;
                    }
                    @media @tablet {
                        height: 77%;
                        margin-top: -18px;
                        top: 104%;
                    }
                    .shape-inner {
                        background: transparent;
                        display: block;
                        height: 100%;
                        left: 0;
                        padding: 38px 50px 50px;
                        position: absolute;
                        text-align: left;
                        top: 0;
                        .transition;
                        width: 100%;
                        z-index: 20;
                        @media @desktop {
                            padding: 13px 25px 15px;
                        }
                    }
                    &::before {
                        background-color: inherit;
                        content: "";
                        height: 393px;
                        left: 81px;
                        position: absolute;
                        top: -197px;
                        .transform(scaleY(0.18) rotate(-45deg));
                        width: 393px;
                        z-index: 10;
                        @media @desktop {
                            height: 323px;
                            left: 65px;
                            top: -161px;
                            width: 323px;
                        }
                        @media @tablet {
                            height: 510px;
                            left: 104px;
                            top: -255px;
                            width: 510px;
                        }
                        @media @mobile {
                            height: 206px;
                            left: 42px;
                            top: -104px;
                            width: 206px;
                        }
                    }
                }
                &:hover {
                    figure {
                        &::before {
                            .opacity(.8);
                        }
                        figcaption {
                            .opacity(1);
                        }
                    }
                    .date {
                        background-color: @blue;
                        left: 50%;
                        margin-left: -35px;
                    }
                    .entry-title {
                        color: @light;
                    }
                    .entry-meta {
                        color: @light;
                        a {
                            color: @light;
                            &:hover {
                               color: @dark;
                            }
                        }
                    }
                    .blog-share {
                        a {
                            border: 1px solid @light;
                            color: @light;
                            i {
                                color: @light;
                            }
                            &:hover {
                                border: 1px solid @dark;
                                color: @dark;
                                i {
                                    color: @dark;
                                }
                            }
                        }
                    }
                    .entry-content {
                        p {
                            color: @light;
                        }
                    }
                    .btn {
                        background-color: @light;
                        color: @dark;
                        &:hover {
                            background-color: @dark;
                            color: @light;
                        }
                    }
                    .shape {
                        background-color: @blue;
                        border-color: @blue;
                        .box-shadow(0 1px 7px rgba(0, 0, 0, .15));
                        margin-top: 0;
                        .opacity(1);
                        top: 100%;
                        .shape-inner {
                            background-color: @blue;
                            padding: 38px 50px 50px;
                            @media @desktop {
                                padding: 13px 25px;
                            }
                        }
                    }
                }
            }
            .date {
                background-color: @dark;
                color: @light;
                font-size: 20px;
                left: 0;
                line-height: 1;
                min-height: 74px;
                min-width: 70px;
                padding: 16px 0 18px;
                position: absolute;
                text-align: center;
                top: 0;
                .transition;
                z-index: 10;
                strong {
                    display: block;
                }
            }
            .btn {
                border: 0;
                font-size: 12px;
                font-weight: 300;
                padding: 8px 10px;
                width: 120px;
            }
        }
    }
    figure {
        position: relative;
        img {
            width: 100%;
        }
        &::before {
            background: @dark;
            content: "";
            height: 100%;
            left: 0;
            .opacity(0);
            position: absolute;
            top: 0;
            .transition;
            width: 100%;
            z-index: 1;
        }
        figcaption {
            left: 50%;
            margin-left: -79px;
            .opacity(0);
            position: absolute;
            top: 50%;
            .transition;
            z-index: 30;
            ul {
                .listreset;
                li {
                    display: inline-block;
                    margin-left: 1px;
                    position: relative;
                    a {
                        background-color: transparent;
                        border: 1px solid @light;
                        .border-radius(50%);
                        display: block;
                        height: 36px;
                        padding: 8px 0;
                        text-align: center;
                        .transition;
                        width: 36px;
                        i {
                            color: @light;
                            font-size: 18px;
                            .transition;
                        }
                        &:hover {
                            background-color: @light;
                            i {
                                color: @blue;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Price Table */
.pricing-table {
    background-color: #fafafa;
    padding: 120px 0;
    p {
        margin-bottom: 41px;
    }
    .basic,
    .silver,
    .platinum,
    .custom {
        @media @desktop1024 {
            cursor: pointer;
        }
        padding: 0 21px;
        position: relative;
        &::before {
            content: "";
            height: 91%;
            left: 0;
            position: absolute;
            top: 6.5%;
            width: 100%;
        }
        .pricing-detail {
            background-color: #fafafa;
            position: relative;
            text-align: center;
            .transition;
            z-index: 99;
        }
        h4 {
            font-size: 30px;
            padding: 24px 0;
        }
        ul {
            list-style: none;
            margin: 127px 15px 0;
            padding: 0 0 16px;
            li {
                color: @dark;
                font-weight: 300;
                margin: 30px 0;
            }
        }
        span {
            color: @light;
            font-size: 50px;
            font-weight: 700;
            left: 0;
            padding: 10px 0;
            position: absolute;
            text-align: center;
            top: 71px;
            width: 100%;
            z-index: 999;
            i {
                font-size: 30px;
                font-weight: 400;
            }
            small {
                font-size: 16px;
                font-weight: 400;
            }
        }
        .btn {
            background-color: #fafafa;
            border-color: #fafafa;
            .border-radius(0);
            font-size: @font-size-base;
            padding: 8px 27px 10px;
            width: 100%;
        }
        &:hover {
            .pricing-detail {
                background-color: @light;
                .box-shadow(0 1px 8px #ccc);
            }
            ul {
                li {
                    color: @dark;
                }
            }
            .btn {
                color: @light;
            }
        }
    }
    .basic {
        &::before {
            border: 1px solid @orange-light;
        }
        h4 {
            color: @orange-light;
        }
        span {
            background-color: @orange-light;
        }
        .btn {
            color: @orange-light;
        }
        &:hover {
            .btn {
                background-color: @orange-light;
                border-color: @orange-light;
            }
        }
    }
    .silver {
        &::before {
            border: 1px solid @silver;
        }
        h4 {
            color: @silver;
        }
        span {
            background-color: @silver;
        }
        .btn {
            color: @silver;
        }
        &:hover {
            .btn {
                background-color: @silver;
                border-color: @silver;
            }
        }
    }
    .platinum {
        &::before {
            border: 1px solid @yellow-light;
        }
        h4 {
            color: @yellow-light;
        }
        span {
            background-color: @yellow-light;
        }
        .btn {
            color: @yellow-light;
        }
        &:hover {
            .btn {
                background-color: @yellow-light;
                border-color: @yellow-light;
            }
        }
    }
    .custom {
        &::before {
            border: 1px solid @blue-light;
        }
        h4 {
            color: @blue-light;
        }
        span {
            background-color: @blue-light;
        }
        .btn {
            color: @blue-light;
        }
        &:hover {
            .btn {
                background-color: @blue-light;
                border-color: @blue-light;
            }
        }
    }
}

/* Our Partners */
.partners {
    padding: 110px 0 120px;
    .owl-carousel {
        display: none;
        margin-top: 34px;
        position: relative;
        width: 100%;
        z-index: 99;
        -webkit-tap-highlight-color: transparent;
        .owl-stage {
            position: relative;
            -ms-touch-action: pan-Y;
            &::after {
                clear: both;
                content: ".";
                display: block;
                height: 0;
                line-height: 0;
                visibility: hidden;
            }
        }
        .owl-stage-outer {
            overflow: hidden;
            position: relative;
            .transform(translate3d(0px, 0px, 0px));
            z-index: 99;
        }
        .owl-item {
            border: 1px solid #e5e5e5;
            float: left;
            height: 168px;
            padding: 10px;
            position: relative;
            text-align: center;
            .transform(translateZ(0));
            -webkit-backface-visibility: hidden;
            -webkit-tap-highlight-color: transparent;
            -webkit-touch-callout: none;
            img {
                .grayscale;
                left: 0;
                margin: 0 auto;
                .opacity(0.55);
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                .transition;
                &:hover {
                    .grayscale(grayscale(0));
                    .opacity(1);
                }
            }
        }
        .owl-nav {
            margin-top: -20px;
            top: 50%;
            position: absolute;
            width: 100%;
            @media @desktop1024 {
                display: none;
            }
            &.disabled {
                display: none;
            }
            .owl-prev,
            .owl-next {
                background-color: transparent;
                border: 1px solid #bdbdbd;
                .border-radius(50%);
                cursor: pointer;
                height: 40px;
                position: absolute;
                text-indent: -99999px;
                .transition;
                .user-select(none);
                width: 40px;
                &::before {
                    color: #bdbdbd;
                    display: block;
                    font-family: 'FontAwesome';
                    font-size: 23px;
                    position: absolute;
                    text-indent: 15px;
                    top: 2px;
                    .transition;
                }
                &:hover {
                    background-color: @blue;
                    border-color: @blue;
                    &::before {
                        color: @light;
                    }
                }
            }
            .owl-prev {
                left: -90px;
                &::before {
                    content: "\f104";
                }
            }
            .owl-next {
                right: -90px;
                &::before {
                    content: "\f105";
                }
            }
        }
        .owl-dots {
            cursor: pointer;
            cursor: hand;
            .user-select(none);
            &.disabled {
                display: none;
            }
        }
        &.owl-loaded {
            display: block;
        }
        &.owl-loading {
            opacity: 1;
            display: block;
        }
        &.owl-hidden {
           opacity: 0;
        }
        &.owl-refresh {
            .owl-item {
               display: none;
            }
        }
        &.owl-drag {
            .owl-item {
                .user-select(none);
            }
        }
        &.owl-grab {
            cursor: move;
            cursor: grab;
        }
        &.owl-rtl {
            direction: rtl;
            .owl-item {
                float: right;
            }
        }
    }
}

/* Footer */
footer {
    position: relative;
    .footer-section {
        background-color: #1c1c1c;
        padding: 215px 0 45px;
    }
    h4 {
        color: @light;
        font-size: @font-size-h3;
        margin: 0 0 45px;
    }
    p {
        color: #aaa;
        line-height: 1.76;
    }
    ul {
        .listreset;
    }
    a {
        &:hover {
            color: @light;
        }
    }
    .tablet-margin-bottom {
        margin-bottom: 42px;
    }
    .about {
        img {
            max-width: 100%;
            margin: 3px 0 24px;
        }
        .read-more {
            color: @blue;
            position: relative;
            &::after {
                content: "\f101";
                font-family: 'FontAwesome';
                font-size: 16px;
                position: absolute;
                right: -15px;
                top: 0;
            }
            &:hover {
                color: @light;
            }
        }
        .social-icons {
            float: left;
            margin-top: 45px;
            width: 100%;
            li {
                float: left;
                text-align: center;
                margin: 0 20px 20px 0;
                @media @desktop {
                    margin: 0 8px 8px 0;
                }
                @media @tablet {
                    margin: 0 17px 17px 0;
                }
                @media @mobile {
                    margin: 0 8px 8px 0;
                }
                a {
                    background-color: transparent;
                    border: 1px solid @light;
                    .border-radius(50px);
                    display: block;
                    padding: 10px 0;
                    height: 40px;
                    width: 40px;
                    &:hover {
                        background-color: @blue;
                        border: 1px solid @blue;
                    }
                    i {
                        color: @light;
                        font-size: 19px;
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    .contact {
        @media @mobile {
            clear: both;
            float: left;
            margin-top: 52px;
            width: 100%;
        }
        .info {
            color: #aaa;
            display: block;
            line-height: 1.75;
            margin-bottom: 30px;
            i {
                float: left;
                font-size: 20px;
                height: 45px;
                margin: 5px 20px 0 0;
                overflow: hidden;
            }
            a {
                color: #aaa;
                &:hover {
                    color: @light;
                }
            }
        }
    }
    .quick-links {
        @media @mobile {
            clear: both;
            float: left;
            margin-top: 24px;
            width: 100%;
        }
        ul {
            li {
                margin-bottom: 10px;
                position: relative;
                a {
                    color: #aaa;
                    margin-left: 15px;
                    &:hover {
                        color: @light;
                    }
                }
                &::before {
                    color: #aaa;
                    content: "\f105";
                    font-family: 'FontAwesome';
                    font-size: 16px;
                    position: absolute;
                    left: 0;
                    top: -1px;
                    .transition;
                }
                &:hover::before {
                    color: @light;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .sub-footer {
        background-color: #0f0f0f;
        padding: 22px 0 23px;
        @media @mobile {
            padding: 23px 0 15px;
        }
        .pull-left,
        .pull-right {
            @media @tabcustom {
                text-align: center;
                width: 100%;
            }
        }
        p {
            font-weight: 300;
            margin: 0;
        }
        ul {
            float: right;
            margin-top: 4px;
            @media @tabcustom {
                float: none;
                margin-top: 19px;
            }
            li {
                float: left;
                margin-left: 50px;
                @media @desktop {
                    margin-left: 30px;
                }
                @media @tabcustom {
                    display: inline-block;
                    float: none;
                }
                @media @tablet {
                    margin: 0 20px;
                }
                @media @mobile {
                    margin: 0 25px 10px;
                }
                a {
                    color: #aaa;
                    font-family: @font-family-serif;
                    .text-transform-uppercase;
                    &:hover {
                        color: @light;
                    }
                }
                &:first-child {
                    margin-left: 0;
                    @media @tablet {
                        margin-left: 20px;
                    }
                    @media @mobile {
                        margin-left: 25px;
                    }
                }
            }
        }
    }
}
