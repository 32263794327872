/*--------------------------------------------------------------
12.1. Posts and Pages
--------------------------------------------------------------*/
.sticky {
    display: block;
}
.hentry {
    margin: 0 0 20px;
}
.byline,
.updated:not(.published) {
    display: none;
}
.single .byline,
.group-blog .byline {
    display: inline;
}
.page-content,
.entry-content,
.entry-summary {
    margin: 20px 0 0;
}
.page-links {
    clear: both;
    margin: 0 0 20px;
}

/* Header Banner (Inner Pages) */
.page-banner {
    background-attachment: fixed;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 550px;
    position: relative;
    text-align: center;
    @media @desktop1024 {
        background-attachment: scroll;
    }
    &::before {
        background: rgba(0, 0, 0, .8);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
    .container {
        left: 0;
        position: absolute;
        right: 0;
        text-align: center;
        top: 50%;
        .transform(translateY(-50%));
    }
    &.about-banner {
        background-image: url(images/page-banners/1920x550.jpg);
    }
    &.blog-banner {
        background-image: url(images/page-banners/1920x550.jpg);
    }
    &.contact-banner {
        background-image: url(images/page-banners/1920x550.jpg);
    }
    &.portfolio-banner {
        background-image: url(images/page-banners/1920x550.jpg);
    }
    &.product-banner {
        background-image: url(images/page-banners/1920x550.jpg);
    }
    &.services-banner {
        background-image: url(images/page-banners/1920x550.jpg);
    }
    &.signin-banner {
        background-image: url(images/page-banners/1920x550.jpg);
    }
}
.lead-text {
    margin-bottom: 15px;
    text-align: center;
}
.lead-text-page {
    font-size: @font-size-large;
    @media @mobile {
        font-size: @font-size-base;
    }
}

/* Blog Grid/List (Template Reference) */

.blog-main-list,
.blog-main-grid {
    padding: 0 0 85px;
    @media @desktop {
        padding: 55px 0 85px;
    }
    @media @tablet {
        padding: 0 0 33px;
    }
    @media @mobile {
        padding: 55px 0 33px;
    }
}

/* Product Grid/List (Template Reference) */

.product-main-list,
.product-main-grid {
    padding: 0;
    @media @mobile, @desktop {
        padding: 55px 0 0;
    }
}

/* Blog/Product List */

.blog-list,
.product-list {
    float: left;
    width: 100%;
    article {
        float: left;
        margin: 0 0 30px;
        position: relative;
        width: 100%;
        @media @desktop1024 {
            cursor: pointer;
        }
        .date,
        .price {
            background-color: @dark;
            color: @light;
            font-size: 20px;
            left: 0;
            line-height: 1;
            min-height: 74px;
            min-width: 70px;
            padding: 16px 0 18px;
            position: absolute;
            text-align: center;
            top: 0;
            .transition;
            z-index: 10;
            strong {
                display: block;
            }
            &::before {
                border-top: 20px solid transparent;
                content: '';
                left: 0;
                position: absolute;
                right: -2px;
                top: -8px;
                .transform(rotate(7.5deg));
                .transition;
            }
        }
        .post-thumbnail {
            float: left;
            width: 370px;
            overflow: hidden;
            position: relative;
            @media @mobile {
                width: 100%;
            }
            img {
                width: 100%;
            }
            &::before {
                border-top: 55px solid transparent;
                content: '';
                left: -25px;
                position: absolute;
                right: -10px;
                top: -25px;
                .transform(rotate(7.5deg));
                .transition;
                z-index: 10;
            }
            &::after {
                background: @dark;
                content: "";
                height: 100%;
                left: 0;
                .opacity(0);
                position: absolute;
                top: 0;
                .transition;
                width: 100%;
                z-index: 1;
            }
        }
        .post-detail {
            background-color: #fafafa;
            float: left;
            min-height: 325px;
            padding: 47px 30px 48px;
            position: absolute;
            right: 0;
            top: 25px;
            .transition;
            width: 570px;
            z-index: 100;
            @media @mobile {
                min-height: 0;
                padding: 47px 20px 50px;
                position: relative;
                top: 0;
                width: 100%;
            }
            &::before {
                background-color: inherit;
                content: '';
                height: 55px;
                left: 0;
                .opacity(0);
                position: absolute;
                top: -28px;
                .transform(skewY(4.5deg));
                .transition;
                width: 100%;
                backface-visibility: hidden;
                -webkit-backface-visibility: hidden;
                @media @mobile {
                    .transform(skewY(3.5deg));
                }
            }
        }
        .entry-header {
            float: left;
            width: 100%;
        }
        .post-info {
            float: left;
            width: 50%;
            @media @mobile {
                width: 100%;
            }
        }
        .post-share {
            float: left;
            margin-top: 3px;
            text-align: right;
            width: 50%;
            @media @mobile {
                margin: 15px 0 3px;
                text-align: left;
                width: 100%;
            }
            a {
                border: 1px solid #929292;
                .border-radius(16px);
                color: #929292;
                display: inline-block;
                font-size: 12px;
                font-weight: 300;
                height: 34px;
                margin-left: 2px;
                padding: 7px 0;
                text-align: center;
                width: 59px;
                @media @mobile {
                    margin: 0 2px 0 0;
                }
                i {
                    color: #929292;
                    .transition;
                }
                &:hover {
                    border: 1px solid @light;
                    color: @light;
                    i {
                        color: @light;
                    }
                }
            }
        }
        h3 {
            margin: 0 0 10px;
            .transition;
        }
        .entry-meta {
            color: #898989;
            font-size: 10px;
            font-weight: 300;
            .text-transform-uppercase;
            .transition;
            a {
                color: #898989;
            }
        }
        .entry-content {
            float: left;
            margin: 0;
            width: 100%;
            p {
                color: #464646;
                line-height: 1.8;
                margin: 22px 0 25px;
                .transition;
            }
        }
        .entry-footer {
            float: left;
            width: 100%;
        }
        .social-icons {
            float: right;
            .listreset;
            text-align: right;
            width: 50%;
            @media @mobile {
                float: left;
                margin-top: 33px;
                text-align: left;
                width: 100%;
            }
            li {
                display: inline-block;
                margin-left: 1px;
                position: relative;
                .transition;
                a {
                    background-color: transparent;
                    border: 1px solid #929292;
                    .border-radius(50%);
                    display: block;
                    height: 36px;
                    padding: 8px 0;
                    text-align: center;
                    width: 36px;
                    i {
                        color: #929292;
                        font-size: 18px;
                        .transition;
                    }
                    &:hover {
                        background-color: @light;
                        border: 1px solid @light;
                        i {
                            color: @blue;
                            .transition;
                        }
                    }
                }
            }
        }
        .btn {
            background-color: transparent;
            border: 1px solid @dark;
            color: @dark;
            font-size: 12px;
            font-weight: 300;
            padding: 8px 10px;
            width: 120px;
        }
        &:hover {
            .date,
            .price {
                background-color: @blue;
                &::before {
                    border-color: @light;
                    @media @mobile {
                        border-color: transparent;
                    }
                }
            }
            .post-thumbnail {
                &::before {
                    border-color: @light;
                    @media @mobile {
                        border-color: transparent;
                    }
                }
                &::after {
                    .opacity(.5);
                }
            }
            .post-detail {
                background-color: @blue;
                &::before {
                    background-color: @blue;
                    .opacity(1);
                }
            }
            .post-share {
                a {
                    border: 1px solid @light;
                    color: @light;
                    display: inline-block;
                    i {
                        color: @light;
                    }
                    &:hover {
                        background-color: @light;
                        color: @blue;
                        i {
                            color: @blue;
                        }
                    }
                }
            }
            h3 {
                color: @light;
            }
            .entry-meta {
                color: @light;
                a {
                    color: @light;
                    &:hover {
                        color: @dark;
                    }
                }
            }
            .entry-content {
                p {
                    color: @light;
                }
            }
            .social-icons {
                li {
                    a {
                        background-color: transparent;
                        border: 1px solid @light;
                        i {
                            color: @light;
                        }
                        &:hover {
                            background-color: @light;
                            i {
                                color: @blue;
                            }
                        }
                    }
                }
            }
            .btn {
                border-color: @light;
                color: @light;
                &:hover {
                    background-color: @light;
                    color: @blue;
                }
            }
        }
        &:last-child {
            margin: 0;
        }
    }
}

/* Product List (Extra Elements) */
.product-list {
    article {
        .rating {
            direction: rtl;
            text-align: left;
            @media @mobile {
                margin-bottom: 15px;
            }
            span {
                color: #898989;
                cursor: pointer;
                display: inline-block;
                position: relative;
                .transition;
                width: 10px;
                &:hover {
                    &~::before,
                    &::before {
                        content: "\2605";
                        position: absolute;
                    }
                }
            }
        }
        .price {
            span {
                text-decoration: line-through;
            }
        }
        .social-icons {
            margin: 0;
        }
        .post-share {
            float: right;
            margin-top: 0;
            @media @mobile {
                text-align: right;
                width: 50%;
            }
            a {
                border-color: @blue;
                .border-radius(20px);
                color: @blue;
                height: 35px;
                margin-left: 0;
                padding: 8px 0;
                .text-transform-uppercase;
                width: 130px;
                @media @mobile {
                    width: 120px;
                }
                i {
                    color: @blue;
                    margin-right: 2px;
                }
            }
        }
        .btn {
            background-color: transparent;
            border-color: @dark;
            color: @dark;
        }
        &:hover {
            .rating {
                span {
                    color: @light;
                }
            }
            .post-share {
                a {
                    &:hover {
                        background-color: @dark;
                        border-color: @dark;
                        color: @light;
                        i {
                            color: @light;
                        }
                    }
                }
            }
        }
    }
}

/* Blog/Product List */
.blog-grid,
.product-grid {
    .tiles-wrap {
        display: none;
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        li {
            cursor: pointer;
            display: block;
            float: left;
            opacity: 0;
            width: 360px;
            @media @tablet {
                width: 345px;
            }
            @media @mobile {
                width: 290px;
            }
            &.wookmark-inactive {
                opacity: 0;
                visibility: hidden;
            }
            .post-caption {
                float: left;
                position: relative;
                width: 100%;
            }
            .entry-header {
                float: left;
                width: 100%;
            }
            .entry-info {
                float: left;
                width: 100%;
            }
            h3 {
                display: block;
                .transition;
            }
            .entry-meta {
                color: #898989;
                font-size: 10px;
                font-weight: 300;
                .text-transform-uppercase;
                .transition;
                a {
                    color: #898989;
                    &:hover {
                       color: @dark;
                    }
                }
            }
            .entry-content {
                margin: 19px 0 0;
                @media @desktop {
                    margin: 12px 0 0;
                }
                p {
                    color: #464646;
                    line-height: 1.8;
                    margin-bottom: 25px;
                    .transition;
                    @media @desktop {
                        line-height: 1.5;
                        margin-bottom: 15px;
                    }
                }
            }
            .shape {
                background-color: transparent;
                border: 1px solid #e5e5e5;
                border-top: 0;
                .opacity(1);
                .transition;
                width: 100%;
                .shape-inner {
                    background: transparent;
                    display: block;
                    padding: 19px 30px 30px;
                    .transition;
                    width: 100%;
                    z-index: 20;
                    @media @desktop {
                        padding: 23px 30px 30px;
                    }
                }
                &::before {
                    background-color: inherit;
                    content: "";
                    height: 256px;
                    left: 52px;
                    position: absolute;
                    top: -128px;
                    .transform(scaleY(0.18) rotate(-45deg));
                    width: 256px;
                    z-index: 10;
                    @media @tablet {
                        height: 245px;
                        left: 50px;
                        top: -123px;
                        width: 245px;
                    }
                    @media @mobile {
                        height: 206px;
                        left: 42px;
                        top: -103px;
                        width: 206px;
                    }
                }
            }
            &:hover {
                figure {
                    &::before {
                        .opacity(.7);
                    }
                    figcaption {
                        .opacity(1);
                    }
                }
                .date,
                .price {
                    background-color: @blue;
                    left: 50%;
                    margin-left: -35px;
                }
                h3 {
                    color: @light;
                }
                .entry-meta {
                    color: @light;
                    a {
                        color: @light;
                        &:hover {
                           color: @dark;
                        }
                    }
                }
                .entry-content {
                    p {
                        color: @light;
                    }
                }
                .btn {
                    background-color: transparent;
                    border-color: @light;
                    color: @light;
                    &:hover {
                        background-color: @light;
                        border-color: @light;
                        color: @blue;
                    }
                }
                .shape {
                    background-color: @blue;
                    border-color: @blue;
                    .box-shadow(0 1px 7px rgba(0, 0, 0, .15));
                    .opacity(1);
                    top: 100%;
                    .shape-inner {
                        background-color: @blue;
                        padding: 19px 30px 30px;
                        @media @desktop {
                            padding: 23px 30px 30px;
                        }
                    }
                }
            }
            .date,
            .price {
                background-color: @dark;
                color: @light;
                font-size: 20px;
                left: 0;
                line-height: 1;
                min-height: 74px;
                min-width: 70px;
                padding: 16px 0 18px;
                position: absolute;
                text-align: center;
                top: 0;
                .transition;
                z-index: 10;
                strong {
                    display: block;
                }
            }
            .btn {
                background-color: transparent;
                border: 1px solid @dark;
                color: @dark;
                font-size: 12px;
                font-weight: 300;
                padding: 8px 10px;
                width: 120px;
            }
        }
        figure {
            position: relative;
            img {
                width: 100%;
            }
            &::before {
                background: @dark;
                content: "";
                height: 100%;
                left: 0;
                .opacity(0);
                position: absolute;
                top: 0;
                .transition;
                width: 100%;
                z-index: 1;
            }
        }
        &.wookmark-initialised {
            li {
                opacity: 1;
            }
            &.animated {
                li {
                    .transition;
                }
            }
        }
    }
    figcaption {
        left: 50%;
        margin: 20px 0 0 -96px;
        .opacity(0);
        position: absolute;
        top: 50%;
        .transition;
        .transform(translateY(-50%));
        z-index: 30;
        @media @mobile {
            left: auto;
            margin: 0;
            right: 5px;
            top: 63px;
        }
        .post-share {
            float: left;
            width: 100%;
            z-index: 10;
            a {
                border: 1px solid @light;
                .border-radius(16px);
                color: @light;
                display: inline-block;
                font-size: 12px;
                font-weight: 300;
                height: 34px;
                margin-left: 2px;
                padding: 7px 0;
                text-align: center;
                width: 59px;
                @media @mobile {
                    display: block;
                    margin-bottom: 5px;
                }
                i {
                    color: @light;
                    .transition;
                }
                &:hover {
                    background-color: @light;
                    color: @blue;
                    i {
                        color: @blue;
                    }
                }
            }
        }
    }
}

/* Product Grid (Extra Elements) */
.product-grid {
    .tiles-wrap {
        li {
            .rating {
                direction: rtl;
                span {
                    color: #898989;
                    display: inline-block;
                    position: relative;
                    .transition;
                    width: 10px;
                    &:hover {
                        &~::before,
                        &::before {
                            content: "\2605";
                            position: absolute;
                        }
                    }
                }
            }
            .price {
                span {
                    text-decoration: line-through;
                }
            }
            &:hover {
                .rating {
                    span {
                        color: @light;
                    }
                }
            }
        }
    }
    figcaption {
        margin: 20px 0 0 -67px;
        @media @mobile {
            margin: 0;
            right: 5px;
            top: 5px;
            .transform(translateY(0));
        }
        .post-share {
            a {
                .text-transform-uppercase;
                width: 130px;
                @media @mobile {
                    font-size: 11px;
                    height: 31px;
                    width: 100px;
                }
                i {
                    margin-right: 3px;
                }
            }
        }
    }
}
.filter-options {
    .search-form {
        position: relative;
        input[type="search"] {
            background-color: transparent;
            border: 1px solid #bcbcbc;
            .border-radius(0);
            color: #4e4e4e;
            font-weight: 400;
            height: 36px;
            line-height: normal;
            padding: 0 40px 0 10px;
            .transition;
            width: 100%;
            &:focus {
                box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 5px rgba(120, 120, 120, 0.5);
            }
        }
        button[type="submit"] {
            background-color: transparent;
            border: 0;
            height: 19px;
            padding: 0;
            position: absolute;
            right: 11px;
            top: 6px;
            width: 19px;
            i {
                color: #bcbcbc;
                font-size: 20px;
                .transition;
            }
            &:hover {
                i {
                    color: @blue;
                }
            }
        }
    }
    .filter-result {
        margin: 8px 0 0;
        @media @mobile {
            margin: 10px 0;
        }
    }
    .filter-toggle {
        text-align: right;
        @media @mobile {
            margin: 0 0 15px;
            text-align: left;
        }
        a {
            border: 1px solid #bcbcbc;
            display: inline-block;
            margin-left: 6px;
            padding: 5px 0 4px 1px;
            text-align: center;
            width: 35px;
            @media @mobile {
                margin-left: 0;
                margin-right: 6px;
            }
            i {
                color: @dark;
                font-size: 20px;
                .transition;
            }
            &.active,
            &:hover {
                background-color: @blue;
                border-color: @blue;
                i {
                    color: @light;
                }
            }
        }
    }
    .select-hidden {
        display: none;
    }
    .select {
        .appearance;
        background-color: transparent;
        .box-shadow(none);
        color: #4e4e4e;
        cursor: pointer;
        height: 35px;
        position: relative;
        width: 100%;
    }
    .select-styled {
        border: 1px solid #bcbcbc;
        padding: 7px 10px 6px;
        &::after {
            content: '\f107';
            color: @dark;
            font-family: 'FontAwesome';
            font-size: 16px;
            margin-top: -3px;
            pointer-events: none;
            position: absolute;
            right: 8px;
            top: 9px;
        }
        &:active,
        &.active {
            &::after {
                content: "\f106";
            }
        }
    }
    .select-list {
        background-color: @light;
        border: 1px solid #bcbcbc;
        border-top: 0;
        display: none;
        left: 0;
        .listreset;
        max-height: 401px;
        overflow-x: hidden;
        overflow-y: auto;
        position: absolute;
        right: 0;
        top: 100%;
        z-index: 999;
        li {
            color: @dark;
            padding: 10px;
            position: relative;
            .transition;
            &::before {
                border-top: 1px solid #e5e5e5;
                content: "";
                left: 10px;
                position: absolute;
                right: 10px;
                top: 0;
                .transition;
            }
            &:first-child {
               &::before {
                    border-top: 0;
                }
            }
            &:hover {
                background-color: @blue;
                color: @light;
                &::before {
                    border-color: transparent;
                }
                & + li {
                    &::before {
                        border-color: transparent;
                    }
                }
            }
            &[rel="hide"] {
                display: none;
            }
        }
    }
    &.margin-grid {
        margin: 24px 0 30px;
    }
    &.margin-list {
        margin: 3px 0 30px;
        .select {
            margin-left: -36px;
        }
    }
}

/* Pagination */
.pagination {
    .border-radius(0);
    margin: 30px 0;
    li {
        a {
            border-color: #c2c2c2;
            .border-radius(50%);
            color: #181818;
            font-weight: 300;
            margin: 0 5px;
            padding: 7px 0px;
            text-align: center;
            width: 36px;
            @media @mobile {
                margin: 0 2px;
                text-align: center;
            }
            &:first-child,
            &:last-child {
                .border-radius(50%);
            }
            &:hover {
                color: @light;
                background-color: @blue;
                border-color: @blue;
            }
        }
        &:first-child {
            a {
                margin-left: 5px;
            }
        }
    }
}
.blog-main-list {
    .filter-options {
        &.margin-list {
            .select {
                @media @desktop {
                    margin-left: -12px;
                }
                @media @tablet {
                    margin-left: -15px;
                }
                @media @mobile {
                    margin-left: 0;
                }
            }
        }
    }
    .filter-result {
        text-align: right;
        @media @mobile {
            margin: 10px 0 0;
            text-align: left;
        }
    }
    .filter-toggle {
        text-align: left;
        a {
            margin-left: 0;
            margin-right: 6px;
        }
    }
}
.product-main-list {
    .filter-options {
        &.margin-list {
            .select {
                @media @desktop {
                    margin-left: -12px;
                }
                @media @tablet {
                    margin-left: -15px;
                }
                @media @mobile {
                    margin-left: 0;
                }
            }
        }
    }
    .filter-result {
        text-align: right;
        @media @mobile {
            margin: 10px 0 0;
            text-align: left;
        }
    }
    .filter-toggle {
        text-align: left;
        a {
            margin-left: 0;
            margin-right: 6px;
        }
    }
}
.blog-main-grid {
    .pagination {
        @media @tabcustom {
            margin: 30px 0 82px;
        }
    }
}
.product-main-list,
.product-main-grid {
    .pagination {
        margin: 30px 0 0;
    }
}
.product-main-list {
    .pagination {
        @media @tabcustom {
            margin: 30px 0;
        }
    }
    .partners {
        @media @tabcustom {
            padding: 55px 0 120px;
        }
    }
}

/* Product Detail */
.product-detail-main {
    padding: 0;
    @media @mobile, @desktop {
        padding: 55px 0 0;
    }
}
.product-detail {
    .product-info {
        margin: 40px 0 120px;
        position: relative;
        @media @tabcustom {
            padding-top: 75px;
        }
        .border {
            border: 20px solid @blue;
            position: relative;
            @media @tabcustom {
                border-width: 10px;
            }
            .price {
                background-color: @dark;
                color: @light;
                font-size: 20px;
                line-height: 1;
                margin-top: -45px;
                min-width: 85px;
                padding: 24px 0 26px;
                position: absolute;
                right: -105px;
                text-align: center;
                top: 50%;
                .transition;
                z-index: 10;
                @media @tabcustom {
                    bottom: -100px;
                    left: 50%;
                    margin: 0px 0 0 -42px;
                    right: auto;
                    top: auto;
                }
                strong {
                    display: block;
                }
                span {
                    text-decoration: line-through;
                }
            }
        }
        .product-description {
            background-color: @blue;
            margin: 80px 0 80px -117px;
            padding: 44px 30px 50px;
            @media @tabcustom {
                margin: -90px 0 15px;
            }
            @media @mobile {
                padding: 30px 15px 1px;
            }
            h3 {
                color: @light;
                font-size: 25px;
                margin: 0 0 5px;
                @media @mobile {
                    font-size: 20px;
                    text-align: center;
                }
            }
            p {
                color: @light;
                line-height: 1.8;
                margin-bottom: 25px;
            }
        }
        .rating {
            direction: rtl;
            text-align: left;
            margin-bottom: 15px;
            @media @mobile {
                text-align: center;
            }
            span {
                color: @light;
                cursor: pointer;
                display: inline-block;
                position: relative;
                width: 10px;
                &:hover {
                    &~::before,
                    &::before {
                        content: "\2605";
                        position: absolute;
                    }
                }
            }
        }
        .select-hidden {
            display: none;
        }
        .select {
            .appearance;
            background-color: @light;
            .box-shadow(none);
            color: @blue;
            cursor: pointer;
            height: 35px;
            margin-bottom: 25px;
            position: relative;
            width: 100%;
        }
        .select-styled {
            padding: 8px 10px 7px;
            &::after {
                content: '\f107';
                color: @blue;
                font-family: 'FontAwesome';
                font-size: 16px;
                margin-top: -3px;
                pointer-events: none;
                position: absolute;
                right: 10px;
                top: 9px;
            }
            &:active,
            &.active {
                &::after {
                    content: "\f106";
                }
            }
        }
        .select-list {
            background-color: @light;
            display: none;
            left: 0;
            .listreset;
            max-height: 401px;
            overflow-x: hidden;
            overflow-y: auto;
            position: absolute;
            right: 0;
            top: 100%;
            z-index: 999;
            li {
                color: @blue;
                padding: 10px;
                position: relative;
                .transition;
                &::before {
                    border-top: 1px solid #e5e5e5;
                    content: "";
                    left: 10px;
                    position: absolute;
                    right: 10px;
                    top: 0;
                    .transition;
                }
                &:first-child {
                   &::before {
                        border-top: 0;
                    }
                }
                &:hover {
                    background-color: @dark;
                    color: @light;
                    &::before {
                        border-color: transparent;
                    }
                    & + li {
                        &::before {
                            border-color: transparent;
                        }
                    }
                }
                &[rel="hide"] {
                    display: none;
                }
            }
        }
        .options {
            margin-bottom: 25px;
            span {
                color: @light;
                display: block;
                margin-bottom: 10px;
                strong {
                    margin-right: 20px;
                }
            }
        }
        .post-share {
            float: left;
            width: 50%;
            @media @mobile {
                width: 100%;
            }
            a {
                border: 1px solid @light;
                .border-radius(20px);
                color: @light;
                display: block;
                font-size: 12px;
                height: 35px;
                margin-left: 0;
                padding: 8px 0;
                text-align: center;
                .text-transform-uppercase;
                width: 130px;
                i {
                    color: @light;
                    margin-right: 2px;
                    .transition;
                }
                &:hover {
                    background-color: @dark;
                    border-color: @dark;
                }
            }
        }
        .social-icons {
            float: right;
            .listreset;
            text-align: right;
            width: 50%;
            @media @mobile {
                float: left;
                margin: 33px 0;
                text-align: left;
                width: 100%;
            }
            li {
                display: inline-block;
                margin-left: 1px;
                position: relative;
                .transition;
                a {
                    background-color: transparent;
                    border: 1px solid @light;
                    .border-radius(50%);
                    display: block;
                    height: 36px;
                    padding: 8px 0;
                    text-align: center;
                    width: 36px;
                    i {
                        color: @light;
                        font-size: 18px;
                        .transition;
                    }
                    &:hover {
                        background-color: @light;
                        border: 1px solid @light;
                        i {
                            color: @blue;
                        }
                    }
                }
            }
        }
        .product-image {
            background-image: url(images/shop/detail/946x771.jpg);
            background-position: center top;
            background-repeat: no-repeat;
            background-size: cover;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            width: 50%;
            z-index: -1;
            @media @tabcustom {
                height: 625px;
                position: relative;
                width: 100%;
                z-index: 1;
            }
            @media @mobile767 {
                height: 520px;
            }
            @media @mobile639 {
                height: 390px;
            }
            @media @mobile479 {
                height: 260px;
            }
        }
    }
}
.product-tabs {
    padding: 0 0 215px;
    position: relative;
    .nav-tabs {
        margin: 0;
        border-bottom: 0;
        li {
            a {
                border: 1px solid #e5e5e5;
                .border-radius(0);
                color: @dark;
                font-size: 16px;
                margin-right: 0;
                width: 170px;
                padding: 12px 15px 13px;
                text-align: center;
                &:hover {
                    background-color: @dark;
                    border-color: @dark;
                    color: @light;
                }
            }
            &.active {
                a {
                    background-color: @blue;
                    border-color: @blue;
                    color: @light;
                }
            }
        }
    }
    .panel-group {
        margin-bottom: 0;
        .panel {
            .border-radius(0);
            box-shadow: none;
            margin-bottom: 10px;
        }
    }
    .panel-default {
        & > .panel-heading {
            background-color: @blue;
            .border-radius(0);
            color: @light;
            font-size: 16px;
            padding: 15px;
            a {
                &:hover,
                &:focus {
                    color: @light;
                }
            }
        }
        .panel-body {
            padding: 35px 15px 15px;
        }
    }
    .tab-content {
        border: 1px solid #e5e5e5;
        padding: 30px 30px 0;
    }
    .options {
        margin: 4px 0 34px;
        @media @mobile479 {
            margin: 0 0 20px;
        }
        span {
            color: @dark;
            display: block;
            margin-bottom: 10px;
            strong {
                margin-right: 20px;
            }
        }
    }
    .star-rating {
        direction: rtl;
        span {
            color: #898989;
            cursor: pointer;
            display: inline-block;
            position: relative;
            width: 10px;
            &:hover {
                &~::before,
                &::before {
                    content: "\2605";
                    position: absolute;
                }
            }
        }
    }
    h4 {
        color: #464646;
        font-size: 20px;
        font-weight: 700;
    }
    p {
        font-size: @font-size-large;
        line-height: 1.9;
        margin-bottom: 30px;
    }
    .comments-area {
        h4 {
            margin: 5px 0 30px;
        }
        .commentlist {
            list-style: none;
            margin: 0;
            padding: 0;
        }
         li {
            img {
                border: 3px solid #f1f1f1;
                float: left;
                margin: 0 15px 15px 0;
                width: 100%;
            }
            .star-rating {
                float: right;
                @media @mobile {
                    float: left;
                    margin-bottom: 10px;
                    text-align: left;
                    width: 100%;
                }
            }
            .meta {
                font-size: @font-size-base;
                margin-bottom: 10px;
            }
            .comment-text {
                background-color: #f4f4f4;
                padding: 20px;
                @media @mobile {
                    float: left;
                }
            }
        }
    }
    .comment-respond {
        margin: 30px 0 9px;
        .comment-reply-title {
            margin: 0 0 20px;
        }
        .star-rating {
            float: left;
            margin: 0 0 30px;
            text-align: left;
            width: 100%;
        }
        .form-group {
            margin-bottom: 30px;
        }
        label {
            color: #464646;
            font-weight: 400;
            width: 100%;
            span {
                color: #f00;
            }
        }
        .form-control {
            background-color: #fafafa;
            border: 0;
            .border-radius(0);
            .box-shadow(none);
            color: @dark;
            height: 45px;
            padding: 0 15px;
        }
        textarea {
            &.form-control {
                height: 145px;
                padding: 20px 15px;
                resize: none;
            }
        }
        .btn {
            background-color: @blue;
            border-color: @blue;
            .border-radius(25px);
            color: @light;
            .transition;
            width: 176px;
            &:hover {
                background-color: @dark;
                border-color: @dark;
            }
        }
    }
}
.recent-list {
    background-color: #fafafa;
    padding: 110px 0 120px;
    @media @tabcustom {
        padding: 110px 0 90px;
    }
    .recent-products {
        list-style: none;
        margin: 30px -15px 0;
        padding: 0;
        li {
            float: left;
            @media @tabcustom {
                margin: 0 0 30px;
            }
        }
        .post-content {
            cursor: pointer;
            .post-caption {
                background-color: @light;
                border: 1px solid #e5e5e5;
                border-top: 0;
                float: left;
                padding: 17px 30px 30px;
                position: relative;
                width: 100%;
            }
            .entry-header {
                float: left;
                width: 100%;
            }
            h4 {
                font-size: @font-size-h3;
            }
            .rating {
                direction: rtl;
                span {
                    color: #898989;
                    display: inline-block;
                    position: relative;
                    width: 10px;
                    &:hover {
                        &~::before,
                        &::before {
                            content: "\2605";
                            position: absolute;
                        }
                    }
                }
            }
            .entry-content {
                margin: 19px 0 0;
                @media @desktop {
                    margin: 12px 0 0;
                }
                p {
                    color: #464646;
                    line-height: 1.8;
                    margin-bottom: 25px;
                    .transition;
                    @media @desktop {
                        line-height: 1.5;
                        margin-bottom: 15px;
                    }
                }
            }
            &:hover {
                figure {
                    &::before {
                        .opacity(.7);
                    }
                    figcaption {
                        .opacity(1);
                    }
                }
                .price {
                    background-color: @blue;
                    left: 50%;
                    margin-left: -35px;
                }
            }
            .price {
                background-color: @dark;
                color: @light;
                font-size: 20px;
                left: 0;
                line-height: 1;
                min-height: 74px;
                min-width: 70px;
                padding: 16px 0 18px;
                position: absolute;
                text-align: center;
                top: 0;
                .transition;
                z-index: 10;
                strong {
                    display: block;
                }
                span {
                    text-decoration: line-through;
                }
            }
            .btn {
                background-color: transparent;
                border: 1px solid @dark;
                color: @dark;
                font-size: 12px;
                font-weight: 300;
                padding: 8px 10px;
                width: 120px;
                &:hover {
                    background-color: @blue;
                    border-color: @blue;
                    color: @light;
                }
            }
        }
        figure {
            position: relative;
            img {
                width: 100%;
            }
            &::before {
                background: @dark;
                content: "";
                height: 100%;
                left: 0;
                .opacity(0);
                position: absolute;
                top: 0;
                .transition;
                width: 100%;
                z-index: 1;
            }
        }
        figcaption {
            left: 50%;
            margin: 36px 0 0 -67px;
            .opacity(0);
            position: absolute;
            top: 50%;
            .transition;
            .transform(translateY(-50%));
            z-index: 30;
            .post-share {
                float: left;
                width: 100%;
                z-index: 10;
                a {
                    border: 1px solid @light;
                    .border-radius(16px);
                    color: @light;
                    display: inline-block;
                    font-size: 12px;
                    font-weight: 300;
                    height: 34px;
                    margin-left: 2px;
                    padding: 7px 0;
                    text-align: center;
                    .text-transform-uppercase;
                    width: 130px;
                    i {
                        color: @light;
                        margin-right: 3px;
                        .transition;
                    }
                    &:hover {
                        background-color: @light;
                        color: @blue;
                        i {
                            color: @blue;
                        }
                    }
                }
            }
        }
    }
}

/* Portfolio Filter */
.portfolio-main {
    padding: 0;
    @media @mobile, @desktop {
        padding: 55px 0 0;
    }
}
.hm-portfolio {
    .filter-buttons {
        margin: 38px 0 0;
        text-align: center;
        .btn {
            font-family: @font-family-serif;
            font-size: 12px;
            color: @dark;
            margin: 0 3px 30px;
            padding: 10px 30px;
            .transition;
            width: auto;
            @media @mobile {
                margin: 0 1% 30px;
                padding: 10px;
                text-align: center;
                width: 47%;
            }
            &.active,
            &:hover {
                background: @blue;
                border-color: @blue;
                color: @light;
            }
        }
    }
    #portfolio-filter {
        margin: 30px 0 7px;
        @media @tabcustom {
            margin: 30px 0 15px;
        }
        .projects-list {
            margin: 0 -1%;
            @media @mobile {
                margin: 0;
            }
        }
        .portfolio-item {
            display: none;
            float: none;
            margin: 0 1% 23px;
            text-align: left;
            width: 23%;
            @media @tablet {
                margin: 0 1% 15px;
                width: 31.33%;
            }
            @media @mobile {
                margin: 0 0 15px;
                width: 100%;
            }
            &.active,
            &:hover {
                .overlay {
                    .opacity(1);
                }
            }
        }
        .overlay {
            background: rgba(0, 0, 0, 0.5);
            color: @light;
            height: 100%;
            .opacity(0);
            position: absolute;
            top: 0;
            text-align: center;
            .transition;
            width: 100%;
            i {
                color: @light;
                font-size: 30px;
                left: 0;
                position: absolute;
                right: 0;
                top: 50%;
                .transform(translateY(-50%));
            }
        }
    }
}

/* Portfolio Expander */
.projects-list {
    font-size: 0;
    list-style: none;
    padding: 0;
    .projects-item {
        .transition (@prop: height, @time: .5s, @ease: ease);
        vertical-align: top;
        &.active {
            .projects-content {
                &::after {
                    border-bottom-width: 15px;
                }
            }
        }
    }
    .projects-content {
        cursor: pointer;
        position: relative;
        &::after {
            border-bottom-color: #f6f6f6;
            border-bottom-style: solid;
            border-bottom-width: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            bottom: 0;
            content: '';
            display: block;
            height: 0;
            left: 50%;
            margin-left: -15px;
            position: absolute;
            .transition (@prop: border-bottom-width, @time: .5s, @ease: ease);
            width: 0;
        }
    }
    .projects-expander {
        background: #f6f6f6;
        color: @dark;
        font-size: @font-size-base;
        font-weight: 300;
        left: 0;
        max-height: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        .transition (@prop: max-height, @time: .5s, @ease: ease);
        .projects-expander-content {
            padding: 50px 15px;
            img {
                cursor: auto;
            }
        }
        h4 {
            font-size: 25px;
            margin: 20px 0 0;
            @media @tabcustom {
                margin: 45px 0 0;
            }
        }
        h5 {
            color: #898989;
            font-size: @font-size-base;
            font-weight: 300;
            margin-bottom: 25px;
            text-transform: none;
        }
        p {
            line-height: 1.85;
            margin-bottom: 7px;
        }
        dl {
            border-bottom: 1px solid #d7d9d8;
            float: left;
            margin: 0;
            padding: 15px 0;
            width: 100%;
            dt,
            dd {
                float: left;
                margin: 0;
                width: 50%;
            }
            dt {
                font-weight: 300;
                padding-right: 20px;
            }
            &:last-child {
                border-bottom-width: 0;
            }
        }
        a {
            background-color: transparent;
            color: @blue-light;
            word-wrap: break-word;
            &:hover {
                color: @dark;
            }
        }
    }
}

/* About Us */
.about-main {
    padding: 0;
    @media @mobile, @desktop {
        padding: 55px 0 0;
    }
}
.about-us {
    .features-list {
        margin-bottom: 95px;
        @media @tablet {
            margin-bottom: 112px;
        }
        @media @mobile {
            margin-bottom: 122px;
        }
        .title {
            float: left;
            width: 100%;
        }
        i {
            float: left;
            font-size: 30px;
            margin: 15px 10px 0 0;
            padding-right: 10px;
            position: relative;
            .transition;
            &::after {
                border-right: 1px solid #dadada;
                content: '';
                height: 29px;
                position: absolute;
                right: -1px;
                top: 0;
            }
        }
        h4 {
            display: inline-block;
            font-weight: 400;
            margin: 20px 0 28px;
            .transition;
            @media @desktop {
                font-size: 16px;
            }
        }
        p {
            line-height: 1.8;
        }
        .item {
            @media @desktop1024 {
                cursor: pointer;
            }
            &:hover {
                i,
                h4 {
                    color: @blue;
                }
            }
        }
    }
    .company-info {
        margin: 0 0 120px;
        position: relative;
        .border {
            border: 20px solid @blue;
            @media @tabcustom {
                border-width: 10px;
            }
        }
        .company-detail {
            background-color: @blue;
            margin: 80px 0 80px -117px;
            padding: 48px 30px 18px;
            @media @tabcustom {
                margin: -90px 0 15px;
            }
            @media @mobile {
                padding: 30px 15px 1px;
            }
            h3 {
                color: @light;
                left: 0;
                margin-bottom: 33px;
                .transform(translateX(0));
                @media @mobile {
                    font-size: 20px;
                }
                &::before,
                &::after {
                    border-color: @light;
                }
            }
            p {
                color: @light;
                font-size: @font-size-large;
                line-height: 1.9;
                margin-bottom: 30px;
            }
        }
        .company-image {
            background-image: url(images/946x771.jpg);
            background-position: center top;
            background-repeat: no-repeat;
            background-size: cover;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            width: 50%;
            z-index: -1;
            @media @tabcustom {
                height: 625px;
                position: relative;
                width: 100%;
                z-index: 1;
            }
            @media @mobile767 {
                height: 520px;
            }
            @media @mobile639 {
                height: 390px;
            }
            @media @mobile479 {
                height: 260px;
            }
        }
    }
}
.skills-in {
    padding: 0 0 120px;
    @media @tablet {
        margin-top: 0;
        padding: 120px 0 92px;
    }
    @media @mobile {
        margin-top: 0;
        padding: 120px 0 90px;
    }
    .row {
        @media @mobile {
            margin-left: -15px;
            margin-right: -15px;
        }
    }
    figure {
        margin-top: 0;
    }
    .work-progress {
        background-color: transparent;
        margin-left: 0;
        padding-top: 110px;
        @media @desktop {
            padding-top: 64px;
        }
        @media @tablet {
            min-height: 0;
            padding-top: 0;
        }
        @media @mobile {
            padding: 50px 0 0;
        }
        h3 {
            left: 0;
            .transform(translateX(0));
            @media @tablet {
                margin-top: 0;
            }
        }
        p {
            margin-bottom: 58px;
        }
        .icon {
            padding: 0 7px 0 0;
            width: 50px;
            span {
                color: #d4d4d4;
            }
        }
        .progress-info {
            margin-bottom: 36px;
            width: 505px;
            @media @desktop {
                width: 405px;
            }
            @media @tablet {
                width: 260px;
            }
            @media @mobile {
                width: 70%;
            }
        }
        .progress {
            border-bottom-color: #d4d4d4;
        }
        .tooltip {
            background-color: inherit;
            .tooltip-inner {
                background-color: inherit;
                color: @light;
            }
        }
        &::before {
            background-color: transparent;
            right: 0;
            width: 0;
        }
    }
}

/* Facts Counter */
.facts-count {
    background-color: #fafafa;
    padding: 120px 0 115px;
    @media @tabcustom {
        padding: 106px 0 75px;
    }
    .facts-item {
        text-align: center;
        @media @tabcustom {
            margin-bottom: 40px;
        }
        i {
            background-image: url(images/facts-icons-sprite.png);
            background-position: 500px 500px;
            background-repeat: no-repeat;
            display: inline-block;
            height: 80px;
            width: 122px;
            &.project {
                background-position: 0px 0px;
            }
            &.custom {
                background-position: -122px 0;
            }
            &.plugin {
                background-position: -244px 0;
            }
            &.experience {
                background-position: -366px 0;
            }
        }
        .fact-counter {
            font-size: 35px;
            font-weight: 800;
            line-height: 1.25;
            margin: 15px 0 0;
        }
        span {
            font-size: @font-size-large;
            .text-transform-uppercase;
        }
    }
}
.team + .partners {
    padding: 60px 0 120px;
}

/* Contact Us */
.contact-main {
    padding: 0;
    @media @mobile, @desktop {
        padding: 55px 0 0;
    }
}
.features-sect {
    margin: 0 0 120px;
    @media @tablet {
        margin: 0 0 80px;
    }
    @media @mobile {
        margin: 0 0 75px;
    }
    .col-sm-6 {
        &:nth-child(3) {
            @media @tablet {
                margin-left: 25%;
            }
        }
    }
    .item {
        cursor: pointer;
        @media @tabcustom {
            margin: 0 0 40px;
        }
        .icon {
            display: inline-block;
            min-height: 140px;
            padding: 43px 0;
            position: relative;
            vertical-align: top;
            width: 70px;
            @media @mobile {
                float: right;
                min-height: 0;
                padding: 15px 0;
            }
            i {
                background-image: url(images/features-icons-sprite.png);
                background-position: 500px 500px;
                background-repeat: no-repeat;
                display: inline-block;
                float: right;
                height: 54px;
                margin-right: 3px;
                .transition(@prop: width, @time: .3s, @ease: ease-in-out);
                width: 57px;
                @media @mobile {
                    margin-right: 2px;
                    width: 100%;
                }
                &.location {
                    background-position: 0px 0px;
                }
                &.phone {
                    background-position: -75px 0;
                }
                &.email {
                    background-position: -150px 0;
                }
                &.retina {
                    background-position: -225px 0px;
                }
                &.responsive {
                    background-position: -300px 0;
                }
                &.support {
                    background-position: -375px 0;
                }
            }
        }
        .icon-bg {
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            .transition;
            width: 2px;
            z-index: -1;
            @media @mobile {
                margin-top: 0;
                width: 100%;
            }
        }
        .info-block {
            display: inline-block;
            margin-top: 30px;
            padding: 27px 25px 22px;
            position: relative;
            width: 286px;
            @media @desktop {
                width: 219px;
            }
            @media @tablet {
                width: 271px;
            }
            @media @mobile {
                margin-top: 0;
                width: 100%;
            }
            h4 {
                font-size: @font-size-h3;
                margin: 0 0 20px;
            }
            p {
                line-height: 1.8;
                margin: 0;
                a {
                    color: @dark;
                    &:hover {
                        color: @blue;
                    }
                }
            }
            &::before {
                .box-shadow(0 1px 7px rgba(0, 0, 0, .15));
                content: '';
                height: 100%;
                left: -4px;
                position: absolute;
                top: 0;
                .transition;
                width: 5px;
                z-index: -2;
                @media @mobile {
                    left: 0;
                    width: 100%;
                }
            }
        }
        &:hover {
            .icon {
                i {
                    margin-right: 2px;
                    width: 70px;
                }
            }
            .icon-bg {
                width: 100%;
            }
            .info-block {
                &::before {
                    width: 100%;
                }
            }
        }
    }
}
.contact-main {
    .features-sect {
        margin: 95px 0 120px;
        @media @tabcustom {
            padding: 0 0 40px;
        }
        @media @mobile {
            margin: 35px 0 60px;
        }
    }
}
.contact-us {
    .contact-form {
        position: relative;
        .border {
            border: 20px solid @blue;
            @media @tabcustom {
                border-width: 10px;
            }
        }
        .contact-fields {
            background-color: @blue;
            margin: 81px 0 81px -117px;
            padding: 48px 30px 18px;
            @media @tabcustom {
                margin: -90px 0 15px;
            }
            @media @mobile {
                padding: 30px 15px 1px;
            }
            h3 {
                color: @light;
                margin-top: 2px;
                margin-bottom: 42px;
                @media @mobile {
                    font-size: 20px;
                }
                &::before,
                &::after {
                    border-color: @light;
                }
            }
        }
        .required {
            color: @red;
        }
        #success,
        #error {
            bottom: 6px;
            display: none;
            float: left;
            padding: 10px;
            position: relative;
            text-align: center;
            width: 100%;
            @media @mobile {
                bottom: 14px;
            }
            span {
                background: @light;
                display: block;
                padding: 5px 10px;
            }
        }
        #success {
            span {
                color: @dark-green;
            }
        }
        #error {
            span {
                color: @red;
            }
        }
        .company-map {
            height: 771px;
            position: absolute;
            right: 0;
            top: 0;
            width: 50%;
            z-index: -1;
            @media @tabcustom {
                height: 625px;
                position: relative;
                width: 100%;
                z-index: 1;
            }
            @media @mobile767 {
                height: 520px;
            }
            @media @mobile639 {
                height: 390px;
            }
            @media @mobile479 {
                height: 260px;
            }
        }
    }
}

/* Services */
.services-main {
    padding: 0;
    @media @mobile, @desktop {
        padding: 55px 0 0;
    }
}
.services-pg {
    .company-desc {
        padding: 100px 0 220px;
        position: relative;
        .detail-sect {
            background-color: @blue;
            padding: 110px 35px 120px;
            position: absolute;
            text-align: center;
            width: 570px;
            z-index: 1;
            @media @tabcustom {
                position: relative;
                width: 100%;
            }
            @media @mobile {
                padding: 40px 15px 120px;
            }
            &::before {
                background-color: @blue;
                content: '';
                height: 100px;
                left: 0;
                position: absolute;
                top: -50px;
                .transform(skewY(-10deg));
                .transition;
                width: 100%;
                backface-visibility: hidden;
                -webkit-backface-visibility: hidden;
                @media @tabcustom {
                    top: -65px;
                    .transform(skewY(-5.4deg));
                }
            }
            h3 {
                color: @light;
                left: 0;
                .transform(translateX(0));
                &::before,
                &::after {
                    border-color: @light;
                }
            }
            p {
                color: @light;
                font-size: @font-size-large;
                line-height: 1.9;
                margin-bottom: 25px;
                &.lead-text {
                    font-size: @font-size-base;
                }
            }
            .btn {
                background-color: @light;
                border-color: @light;
                color: @dark;
                margin: 0 4px;
                &:hover {
                    background-color: @dark;
                    border-color: @dark;
                    color: @light;
                }
            }
        }
        figure {
            float: right;
            position: relative;
            overflow: hidden;
            width: 771px;
            @media @tabcustom {
                width: 100%;
            }
            &::before {
                background-color: @light;
                content: '';
                height: 100px;
                left: 0;
                position: absolute;
                top: -40px;
                .transform(skewY(-8.5deg));
                .transition;
                width: 100%;
                backface-visibility: hidden;
                -webkit-backface-visibility: hidden;
                @media @tablet {
                    top: -60px;
                    .transform(skewY(-6.3deg));
                }
                @media @mobile {
                    background-color: transparent;
                }
            }
            img {
                width: 100%;
            }
        }
    }
    .services {
        background-color: #fafafa;
        padding: 110px 0 70px;
        @media @tablet {
            padding: 110px 0 85px;
        }
        .services-list {
            margin-top: 55px;
        }
        .col-sm-6 {
            &:nth-child(3) {
                @media @tablet {
                    margin-left: 0;
                }
            }
        }
        .items {
            margin-bottom: 40px;
            @media @tablet {
                margin-bottom: 25px;
            }
            .shape {
                background-color: #fafafa;
                height: 390px;
                @media @desktop {
                    height: 415px;
                }
                @media @mobile {
                    height: 470px;
                }
                i {
                    background-image: url(images/services-icons-sprite.png);
                    height: 51px;
                    width: 61px;
                    &.wordpress {
                        background-position: 0 0;
                    }
                    &.development {
                        background-position: -61px 0;
                    }
                    &.plugin {
                        background-position: -122px 0;
                    }
                    &.maintenance {
                        background-position: -183px 0;
                    }
                    &.parallax {
                        background-position: -244px 0;
                    }
                    &.theme {
                        background-position: -305px 0;
                    }
                }
                hr {
                    background-color: inherit;
                    bottom: 25px;
                    @media @mobile {
                        bottom: 20px;
                    }
                }
            }
            &:hover {
                .shape {
                    background-color: @light;
                    i {
                        &.wordpress {
                            background-position: 0 -51px;
                        }
                        &.development {
                            background-position: -61px -51px;
                        }
                        &.plugin {
                            background-position: -122px -51px;
                        }
                        &.maintenance {
                            background-position: -183px -51px;
                        }
                        &.parallax {
                            background-position: -244px -51px;
                        }
                        &.theme {
                            background-position: -305px -51px;
                        }
                    }
                    p {
                        color: @dark;
                    }
                }
                &::after {
                    border-color: @dark;
                    height: 336px;
                    @media @desktop {
                        height: 361px;
                    }
                    @media @mobile {
                        height: 421px;
                    }
                }
            }
        }
    }
    .company-info {
        padding: 120px 0 50px;
        @media @tabcustom {
            padding: 200px 0 50px;
        }
        position: relative;
        .border-blue {
            border: 20px solid @blue;
            border-right: 0;
            padding: 0;
            @media @tabcustom {
                border: 10px solid @blue;
                margin-bottom: 140px;
            }
            .btn {
                color: @light;
                &:hover {
                    background-color: @light;
                    border-color: @light;
                    color: @dark;
                }
            }
        }
        .border-dark {
            border: 20px solid @dark;
            border-left: 0;
            padding: 0;
            @media @tabcustom {
                border: 10px solid @dark;
            }
            .btn {
                border-color: @light;
                color: @blue;
                &:hover {
                    border-color: @dark;
                    color: @light;
                }
            }
        }
        .margin-left {
            margin: 80px 0 80px -115px;
            @media @tabcustom {
                margin: -90px 10px 10px;
            }
        }
        .margin-right {
            margin: 80px -115px 80px 0;
            @media @tabcustom {
                margin: -90px 10px 10px;
            }
        }
        .company-detail {
            padding: 110px 30px 120px;
            @media @tablet {
                padding: 50px 30px 60px;
            }
            @media @mobile {
                padding: 30px 15px 40px;
            }
            h3 {
                color: @light;
                left: 0;
                margin-bottom: 33px;
                .transform(translateX(0));
                @media @mobile {
                    font-size: 20px;
                }
                &::before,
                &::after {
                    border-color: @light;
                }
            }
            p {
                color: @light;
                font-size: @font-size-large;
                line-height: 1.9;
                margin-bottom: 22px;
            }
        }
    }
}
.hm-portfolio + .partners {
    padding: 50px 0 120px;
}

/* WooCommerce */
.woocommerce-main {
    padding: 0;
    @media @desktop {
        padding: 55px 0 0;
    }
    @media @mobile {
        padding: 55px 0 0;
    }
}
.woocommerce {
    .btn {
        background-color: transparent;
        border-color: @dark;
        color: @dark;
        font-family: @font-family-serif;
        font-size: 12px;
        padding: 10px;
        .transition;
        width: 130px;
        @media @mobile {
            margin: 0 0 27px;
        }
        &:hover {
            background-color: @dark;
            border-color: @dark;
            color: @light;
        }
        &.checkout-button {
            border-color: @blue;
            color: @blue;
            @media @mobile {
                margin: 0;
            }
            &:hover {
                background-color: @blue;
                border-color: @blue;
                color: @light;
            }
        }
    }
    .woocommerce-checkout {
        margin: 21px 0 0;
        @media @tabcustom {
            margin: 52px 0 0;
        }
        .btn-primary {
            background-color: @blue;
            border-color: @blue;
            color: @light;
            &:hover {
                background-color: @dark;
                border-color: @dark;
            }
        }
        .btn-default {
            background-color: transparent;
            border-color: @dark;
            color: @dark;
        }
        .btn {
            width: 110px;
            margin: 0;
            &:hover {
                background-color: @dark;
                border-color: @dark;
                color: @light;
            }
        }
        #place_order {
            border-color: @blue;
            clear: both;
            color: @blue;
            float: left;
            width: 130px;
            &:hover {
                background-color: @blue;
                border-color: @blue;
                color: @light;
            }
        }
        .woocommerce-billing-fields {
            @media @mobile {
                margin-bottom: 30px;
            }
        }
    }
    .woocommerce-login {
        margin-top: 20px;
        @media @tabcustom {
            margin-top: 52px;
        }
        .btn-primary {
            background-color: @blue;
            border-color: @blue;
            color: @light;
            @media @mobile {
                margin-bottom: 47px;
            }
            &:hover {
                background-color: #0088bc;
                border-color: #0088bc;
            }
        }
        .btn-default {
            background-color: @dark;
            border-color: @dark;
            color: @light;
            margin: 0;
            &:hover {
                background-color: #555;
                border-color: #555;
            }
        }
    }
    .shop_table {
        border: 1px solid #e5e5e5;
        border-spacing: 0;
        &.cart {
            margin: 24px 0 97px;
            @media @tabcustom {
                margin: 55px 0 27px;
            }
        }
        thead {
            th {
                font-size: @font-size-large;
            }
        }
        tr {
            border-bottom: 1px solid #e5e5e5;
            line-height: 14px;
        }
        td {
            font-size: @font-size-base;
            line-height: 14px;
            padding: 20px;
            @media only screen and (max-width: 760px)  {
                line-height: 18px;
                padding: 10px;
            }
        }
        th {
            line-height: 14px;
            padding: 20px;
            .text-transform-uppercase;
        }
        .product-name {
            & > span {
                display: inline-block;
                vertical-align: middle;
                &.product-thumbnail {
                    margin-right: 30px;
                    @media @mobile {
                        margin-right: 0;
                    }
                }
                img {
                    border: 1px solid #e5e5e5;
                }
                a {
                    color: @dark;
                    display: block;
                    font-size: 16px;
                    &:hover {
                        color: @blue;
                    }
                }
                small {
                    color: #6b6b6b;
                    display: block;
                    font-size: 13px;
                    line-height: 20px;
                    margin: 15px 0;
                }
                strong {
                    font-size: 13px;
                }
                &:nth-child(even) {
                    width: 41%;
                    @media @tabcustom {
                        width: 51%;
                    }
                    @media only screen and (max-width: 760px)  {
                        margin-left: 10px;
                        width: 70%;
                    }
                }
            }
        }
        .quantity {
            .qty {
                .border-radius(0);
                color: @dark;
                height: 42px;
                text-align: center;
                width: 68px;
            }
        }
        .product-remove {
            a {
                color: #f00;
                font-size: 20px;
                &:hover {
                    color: @blue;
                }
            }
        }
    }
    .woocommerce-checkout-review-order-table {
        @media @tabcustom {
            margin: 0 0 47px;
        }
        tr {
            border-bottom: 0;
        }
        td {
            border-bottom: 0;
            .text-transform-uppercase;
        }
        thead {
            border-bottom: 1px solid #e5e5e5;
        }
        tbody {
            border-bottom: 1px solid #e5e5e5;
            .cart_item {
                &:first-child {
                    td {
                        padding: 17px 20px 5px;
                    }
                }
                &:last-child {
                    td {
                        padding: 5px 20px 17px;
                    }
                }
            }
        }
        tfoot {
            border-bottom: 1px solid #e5e5e5;
        }
        .cart-subtotal td {
            padding: 20px 20px 6px;
        }
        .cart-shipping td {
            padding: 10px 20px;
        }
        .order-total th {
            padding: 6px 20px 20px;
        }
        .shipping-amount {
            color: @blue;
        }

    }
    .cart-collaterals {
        h3 {
            margin: 20px 0 10px;
        }
        p {
            margin-bottom: 40px;
        }
        .shop_table {
            border: none;
            margin: 16px 0 30px;
            td {
                font-size: @font-size-large;
                padding: 21px 0;
                &:nth-child(2) {
                    text-align: right;
                }
            }
            th {
                font-size: @font-size-large;
                padding: 20px 0;
                &:nth-child(2) {
                    text-align: right;
                }
            }
        }
    }
}
.woocommerce-checkout,
.woocommerce-login {
    h3 {
        display: block;
        font-size: 25px;
        left: 0;
        line-height: 22px;
        margin: 0 0 48px;
        padding: 0;
        text-align: left;
        .transform(translateX(0));
        .transition;
        &::after,
        &::before {
            border-width: 0;
        }
    }
}
.woocommerce-info {
    border: 1px solid #e5e5e5;
    padding: 30px 15px;
    @media @tabcustom {
        margin-bottom: 50px;
    }
    @media @mobile {
        div {
            &:first-child {
                margin-bottom: 30px;
            }
        }
    }
    h4 {
        font-size: 20px;
        margin: 0 0 10px;
        @media @tablet {
            font-size: 16px;
        }
    }
    .radio {
        margin-bottom: 0;
            label {
                margin-bottom: 21px;
            }
        }
}
.radio {
    margin: 0 0 21px;
    label {
        clear: both;
        color: #6b6b6b;
        cursor: default;
        float: left;
        font-weight: 300;
        margin-bottom: 30px;
        padding-left: 25px;
        position: relative;
        &::before {
            background-color: @light;
            border: 1px solid #c4c4c4;
            .border-radius(50%);
            content: "";
            display: inline-block;
            height: 14px;
            left: 0;
            margin-right: 10px;
            position: absolute;
            top: 4px;
            width: 14px;
        }
    }
    input[type="radio"] {
        box-sizing: border-box;
        display: none;
        line-height: normal;
        padding: 0;
        &:checked {
            & {
               + label {
                    &::before {
                        color: #c1c1c1;
                        content: "\2022";
                        font-size: 25px;
                        line-height: 11px;
                        text-align: center;
                    }
                }
            }
        }
    }
}
#customer_details {
    margin-top: 97px;
    @media @tablet {
        margin-top: 70px;
    }
    @media @mobile {
        margin-top: 0px;
    }
}
.woocommerce-checkout-payment {
    margin-top: 85px;
    @media @mobile {
        margin-top: 30px;
    }
    .radio {
        label {
            color: @dark;
            font-size: @font-size-large;
            strong {
                display: block;
                font-size: 18px;
                margin-bottom: 20px;
            }
            p {
                font-weight: 300;
                margin-bottom: 0;
            }
            &::before {
                top: 8px;
            }
        }
    }
    .place-order {
        img {
            @media @mobile {
                width: 50px;
            }
        }
    }
}
.social-media-signin {
    display: block;
    margin-bottom: 30px;
    a {
        background-color: #3d5c98;
        color: @light;
        display: inline-block;
        float: left;
        font-size: 20px;
        font-weight: 700;
        line-height: 18px;
        margin-right: 30px;
        padding: 17px 0;
        text-align: center;
        .text-transform-uppercase;
        width: 100%;
        &:hover {
            background-color: #3d5c7d;
        }
        @media @mobile {
            padding: 10px 0;
        }
    }
    .twitter {
        background-color: #1ab2e8;
        margin-right: 0;
        &:hover {
            background-color: #0088bc;
        }
    }
}
.input-checkbox {
    display: inline-block;
    label {
        color: #6b6b6b;
        font-weight: 300;
        margin-bottom: 0;
        padding-left: 30px;
        &::before {
            background-color: transparent;
            border: 1px solid @blue;
            content: "";
            display: inline-block;
            height: 17px;
            left: 15px;
            position: absolute;
            width: 17px;
        }
    }
    input[type="checkbox"] {
        display: none;
        &:checked {
            & {
                + label {
                    &::before {
                        background-color: @blue;
                        color: @light;
                        content: "\2713";
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 15px;
                        text-align: center;
                    }
                }
            }
        }
    }
}
.password-form-row {
    display: block;
    margin-bottom: 30px;
}
.lost_password {
    display: inline-block;
    float: right;
    text-align: right;
}
@media @mobile {
    .shop_table {
        &.cart {
            thead {
                display: none;
            }
        }
        .product-thumbnail {
            float: left;
            width: 26%;
            img {
                height: auto;
                width: 100% !important;
            }
        }
        .product-remove {
            float: left;
            margin-top: 13px;
            width: 7%;
        }
        .product-name {
            float: left;
            width: 100%;
        }
        .product-price {
            float: left;
            margin-top: 13px;
            width: 25%;
        }
        .product-quantity {
            float: left;
            width: 28%;
            .quantity {
                .qty {
                    width: 100%;
                }
            }
        }
        .product-subtotal {
            float: left;
            margin-top: 13px;
            width: 25%;
        }
        .cart_item {
            td {
                &:nth-of-type(6) {
                    &::before {
                        content: "=  ";
                    }
                }
            }
            td {
                &:nth-of-type(7) {
                    &::before {
                        content: "";
                        vertical-align: baseline;
                    }
                }
            }
        }
    }
}

/* 404 Page */
.error-page {
    background-attachment : local;
    background-image: url(images/error-page/745x1004.jpg), url(images/error-page/1175x1004.jpg);
    background-position : left top, right top;
    background-repeat : no-repeat;
    display: block;
    height: 100vh;
    @media @mobile {
        height: 100%;
    }
}
.error-main {
    padding: 60px 0;
}
.error-view {
    display: table;
    height: 87vh;
    width: 100%;
}
.error-section {
    display: table-cell;
    vertical-align: middle;
    @media @mobile {
        text-align: center;
    }
    h2 {
        color: @light;
        display: block;
        font-size: 313px;
        left: 0;
        letter-spacing: -17px;
        line-height: 205px;
        margin: 0;
        padding: 0;
        text-align: left;
        .transform(translateX(0));
        @media @desktop {
            font-size: 237px;
            letter-spacing: -26px;
            margin-top: 100px;
        }
        @media @tablet {
            font-size: 170px;
            letter-spacing: -4px;
        }
        @media @mobile {
            font-size: 80px;
            letter-spacing: -2px;
            line-height: 80px;
            margin-top: 100px;
            text-align: center;
        }
        &::after,
        &::before {
            border-width: 0;
        }
        span {
            color: @blue;
        }
    }
    .col-sm-6 {
        @media @desktop {
            margin: 79px 0 0 -36px;
        }
        @media @tablet {
            margin: -13px 0 0 -16px;
        }
    }
    h3 {
        color: @blue;
        font-size: 70px;
        letter-spacing: -2px;
        margin: 20px 0 15px;
        padding: 0 0 0 15px;
        @media @tablet {
            font-size: 60px;
            padding: 0;
        }
        @media @mobile {
            font-size: 40px;
            padding: 0;
            text-align: center;
        }
        small {
            color: @blue;
            display: block;
            font-size: 50px;
            font-weight: 400;
            text-transform: none;
            @media @tablet {
                font-size: 35px;
                padding-left: 0;
            }
            @media @mobile {
                font-size: 30px;
            }
        }
    }
    .search-404 {
        position: relative;
        @media @desktop {
            width: 440px;
        }
        @media @tablet {
            width: 330px;
        }
        @media @mobile {
            width: 100%;
        }
    }
    input[type="text"] {
        background: @light;
        border: none;
        .border-radius(0);
        color: @dark;
        display: inline-block;
        float: left;
        height: 50px;
        padding: 0 80px 0 30px;
        width: 100%;
    }
    input[type="submit"] {
        background: url("images/search-icon.png") no-repeat center center @blue;
        border: 0;
        .border-radius(0);
        height: 50px;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        text-indent: -9999px;
        .transition;
        width: 50px;
        &:hover {
            background-color: @dark;
        }
    }
    p {
        clear: both;
        font-family: @font-family-serif;
        font-weight: 500;
        padding: 10px 0 0 15px;
        @media @tablet {
            padding-left: 0;
        }
        @media @mobile {
            padding: 10px 0 0 0;
            color: @light;
        }
    }
    .btn {
        color: @light;
        margin-left: 15px;
        width: 170px;
        @media only screen and (max-width: 991px){
            margin-left: 0;
        }
        &:hover {
            background-color: @dark;
            border-color: @dark;
            @media @mobile {
                background-color: @light;
                border-color: @light;
                color: @dark;
            }
        }
    }
}

/* Blog Deatil */
.blog-detail-main {
    padding: 0;
    @media @mobile, @desktop {
        padding: 55px 0 0;
    }
}
.blog-detail {
    position: relative;
    margin-bottom: 100px;
    @media @mobile {
        margin-bottom: 50px;
    }
}
.post-thumb {
    img {
        left: 0;
        top: -50px;
        position: absolute;
        z-index: -1;
        @media @mobile {
            top: -15px;
        }
    }
}
.detail-discription {
    border: 10px solid @blue;
    display: block;
    margin-top: 50px;
    padding: 0 50px;
    @media @tabcustom {
        padding: 0 20px;
    }
    @media @mobile {
        border: 5px solid @blue;
        padding: 0 15px;
        margin-top: 30px;
    }
    .dates {
        background: @blue;
        margin: 0 auto 265px;
        padding: 10px 0 20px;
        text-align: center;
        width: 70px;
        @media @tabcustom {
            margin-bottom: 200px;
        }
        @media @mobile {
            margin-bottom: 60px;
            padding: 10px 0;
        }
        span {
            color: @light;
            display: block;
            font-size: 20px;
            line-height: 20px;
            @media @mobile {
                font-size: 16px;
                line-height: 16px;
            }
        }
        span.month {
            font-weight: 700;
        }
    }
}
.detail-content-box {
    color: @light;
    background: @blue;
    margin-bottom: -50px;
    padding: 50px 30px 20px;
    @media @mobile {
        padding: 20px;
    }
    .entry-header {
        margin-bottom: 30px;
        h3 {
            color: @light;
            display: block;
            font-size: 25px;
            line-height: 25px;
            margin: 0 0 10px;
            @media @mobile {
                font-size: 18px;
                line-height: 18px;
            }
        }
        .entry-meta {
            .text-transform-uppercase;
            span {
                color: @light;
                display: inline-block;
                font-size: 12px;
                font-weight: 300;
                a {
                    color: @light;
                    &:hover {
                        color: @dark;
                    }
                }
            }
            .author {
                &::after {
                    content: "/";
                    display: inline-block;
                    margin: 0 7px;
                }
            }
        }
    }
    .social-count {
        text-align: right;
        @media @mobile {
            margin-top: 30px;
            text-align: center;
        }
        .btn {
            background: transparent;
            border: 1px solid @light;
            color: @light;
            display: inline-block;
            margin-left: 6px;
            padding: 10px 20px;
            width: auto;
            @media @desktop {
                padding: 8px 15px;
            }
            &:hover {
                background: @light;
                color: @blue;
            }
        }
    }
    .entry-content {
        blockquote {
            border-left: 0;
            display: block;
            font-size: 18px;
            margin: 50px 0;
            padding: 0 10px;
            text-align: center;
            @media @mobile {
                margin: 30px 0;
            }
            &::before {
                color: @light;
                content: '\201C';
                display: block;
                font-family: 'Montserrat', sans-serif;
                font-size: 150px;
                font-weight: 700;
                line-height: 80px;
                text-align: center;
                @media @mobile {
                    font-size: 100px;
                }
            }
            em {
                display: block;
                font-size: @font-size-base;
                font-weight: 700;
                line-height: 14px;
                margin-top: 50px;
                .text-transform-uppercase;
                @media @mobile {
                    margin-top: 30px;
                }
            }
        }
    }
}
.blog-detail-box {
    margin-bottom: 100px;
    @media @tabcustom {
        margin-bottom: 35px;
    }
    .entry-footer {
        display: block;
        margin-top: 80px;
        padding: 0 90px;
        @media @tabcustom {
            padding: 0 59px;
        }
        @media @mobile {
            padding: 0 20px;
        }
    }
    .entry-tags {
        strong {
            color: #464646;
            display: inline-block;
            font-size: 12px;
            line-height: 21px;
            .text-transform-uppercase;
            vertical-align: top;
        }
        span {
            display: inline-block;
            width: 90%;
            @media @mobile {
                width: 84%;
            }
        }
        a {
            color: #464646;
            display: inline-block;
            @media @mobile {
                font-size: 12px;
            }
            &:hover {
                color: @blue;
            }
        }
    }
    .share {
        ul {
            text-align: right;
            @media @mobile {
                margin-top: 30px;
                text-align: center;
            }
            li {
                display: inline-block;
                text-align: right;
                @media @mobile {
                    margin: 0 5px;
                 }
                a {
                    border: 1px solid #929292;
                    .border-radius(30px);
                    color: #929292;
                    display: inline-block;
                    font-size: 16px;
                    height: 36px;
                    line-height: 33px;
                    text-align: center;
                    width: 36px;
                    &:hover {
                        background-color: @blue;
                        border-color: @blue;
                        color: @light;
                    }
                }
            }
        }
    }
}
.comments-area {
    h4 {
        color: #464646;
        font-size: @font-size-h3;
        margin: 0 0 30px;
    }
    ol {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    ol.children {
        margin: 0 0 0 100px;
        @media @mobile {
            margin: 0 0 0 50px;
        }
    }
    li {
        .comment-body {
            display: block;
            margin-bottom: 30px;
        }
    }
    .comment-author {
        display: inline-block;
        margin-right: 30px;
        vertical-align: top;
        width: 7.5%;
        @media @mobile {
            width: 14%;
            margin-right: 10px;
        }
        img {
            border: 3px solid #f1f1f1;
            display: block;
            height: 64px;
            margin-bottom: 10px;
            width: 64px;
            @media @tabcustom {
                height: auto;
                width: 100%;
            }
        }
        .fn {
            a {
                color: #464646;
                display: block;
                text-align: center;
                &:hover {
                    color: @blue;
                }
            }
        }
    }
    .comment-meta {
        background-color: #f4f4f4;
        display: inline-block;
        padding: 20px 20px 0 20px;
        position: relative;
        width: 88.5%;
        @media @tabcustom {
            width: 86%;
        }
        @media @mobile {
            width: 81%;
        }
        .left-arrow {
            border-bottom: 10px solid transparent;
            border-right:10px solid #f4f4f4;
            border-top: 10px solid transparent;
            height: 0;
            left: -10px;
            position: absolute;
            top: 20px;
            width: 0;
            @media @mobile {
                left: -7px;
                top: 10px;
            }
        }
        .comment-metadata {
            display: inline-block;
            margin-bottom: 20px;
            @media @mobile {
                display: block;
                float: left;
                margin: 20px 0;
            }
        }
        a {
            time {
                color: #888;
                display: inline-block;
                b {
                    color: #464646;
                    font-weight: 400;
                    .transition;
                }
            }
            &:hover {
                b {
                    color: @blue;
                }
            }
        }
    }
    .children {
        .comment-meta {
            width: 88%;
            @media @tabcustom {
            width: 85%;
            }
            @media @mobile {
                width: 80%;
            }
        }
    }
    .comment-content {
        clear: both;
        display: block;
        p {
            clear: both;
            color: #464646;
            display: block;
        }
    }
}
.reply {
    float: right;
    margin: -20px -20px 0 0;
    a {
        background: @blue;
        color: @light;
        display: block;
        font-size: 12px;
        line-height: 16px;
        padding: 10px;
        &:hover {
            background: @dark;
        }
    }
}
.comment-respond {
    display: block;
    margin-top: 100px;
    @media @mobile {
        margin: 50px 0;
    }
    label {
        color: #464646;
        display: block;
        font-weight: 400;
        margin-bottom: 7px;
        span {
            color: #f00;
        }
    }
    input[type="text"],
    input[type="email"],
    textarea {
        border: none;
        background-color: #fafafa;
        .border-radius(0);
        color: #464646;
        height: 45px;
        padding: 0 15px;
        width: 100%;
    }
    .comment-notes {
        display: none;
    }
}
.comment-form-comment {
    textarea {
        height: 233px;
        padding: 15px;
        resize: none;
    }
}
.form-submit {
    #submit {
        background-color: @dark;
        border: none;
        clear: both;
        color: @light;
        display: block;
        margin: 10px auto 0;
        padding: 10px 30px;
        .text-transform-uppercase;
        .transition;
        &:hover {
            background-color: @blue;
        }
    }
}

/*--------------------------------------------------------------
12.2. Asides
--------------------------------------------------------------*/

.blog,
.archive {
    .format-aside {
        .entry-title {
            display: none;
        }
    }
}
.sidebar {
    .widget {
        float: left;
        margin: 0 0 57px;
        width: 100%;
        h4 {
            font-size: @font-size-h3;
            margin: 0 0 26px;
        }
        ul {
            .listreset;
        }
    }
    .widget_search {
        .search-form {
            position: relative;
            label {
                display: block;
                margin: 0;
            }
            .screen-reader-text {
                clip: rect(1px, 1px, 1px, 1px);
                height: 1px;
                overflow: hidden;
                position: absolute;
                width: 1px;
                word-wrap: normal;
            }
            input[type="search"] {
                background-color: transparent;
                border: 1px solid #bcbcbc;
                .border-radius(0);
                color: #4e4e4e;
                font-weight: 400;
                height: 50px;
                line-height: normal;
                padding: 0 60px 0 10px;
                .transition;
                width: 100%;
                &:focus {
                    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 5px rgba(120, 120, 120, 0.5);
                }
            }
            input[type="submit"] {
                background: url("images/search-icon.png") no-repeat center center @dark;
                border: 0;
                .border-radius(0);
                height: 50px;
                padding: 0;
                position: absolute;
                right: 0;
                text-indent: -9999px;
                top: 0;
                .transition;
                width: 50px;
                &:hover {
                    background-color: @blue;
                }
            }
        }
    }
    .widget_categories {
        ul {
            li {
                margin: 10px 0;
                a {
                    color: #464646;
                    display: block;
                    span {
                        float: right;
                    }
                    &:hover {
                        color: @blue;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .widget_price {
        .price-slider {
            float: left;
            margin-bottom: 50px;
            width: 100%;
        }
        .price-filter {
            float: left;
            width: 100%;
            .price-range {
                float: left;
                padding-top: 7px;
                width: 50%;
                @media @desktop {
                    width: 56%;
                }
            }
            .filter-button {
                float: left;
                width: 50%;
                @media @desktop {
                    width: 44%;
                }
            }
            .filter-button {
                text-align: right;
                .btn {
                    background-color: @blue;
                    border: 0;
                    .border-radius(@radius: 20px);
                    color: @light;
                    padding: 7px 10px;
                    .transition;
                    width: 120px;
                    @media @desktop {
                        width: 100%;
                    }
                    &:hover {
                        background-color: @dark;
                    }
                }
            }
        }
        .slider {
            float: left;
            position: relative;
            width: 100%;
            .slider-track {
                background-color: @dark;
                .border-radius(@radius: 5px);
                cursor: pointer;
                height: 10px;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
            .slider-selection {
                background-color: @blue;
                .border-radius(@radius: 5px);
                bottom: 0;
                height: 100%;
                position: absolute;
                top: 0;
            }
            .slider-handle {
                background-color: @blue;
                border: 1px solid @blue;
                box-shadow: 0px 0px 0px 2px @light inset;
                .border-radius(@radius: 50%);
                height: 24px;
                margin-left: -10px;
                margin-top: -7px;
                position: absolute;
                width: 24px;
            }
            input {
                display: none;
            }
        }
    }
    .widget_recent_entries {
        ul {
            li {
                float: left;
                margin: 0 0 30px;
                width: 100%;
                figure {
                    float: left;
                    img {
                        border: 3px solid #e5e5e5;
                        margin-right: 10px;
                    }
                }
                a {
                    color: @dark;
                    display: block;
                    margin: 8px 0 5px;
                    &:hover {
                        color: @blue;
                    }
                }
                .post-date {
                    color: #6b6b6b;
                    i {
                        margin-right: 8px;
                    }
                }
                .price {
                    color: @blue;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .widget_archive {
        .select-hidden {
            display: none;
        }
        .select {
            .appearance;
            background-color: transparent;
            .box-shadow(none);
            color: #4e4e4e;
            cursor: pointer;
            font-size: 14px;
            height: 46px;
            position: relative;
            width: 100%;
        }
        .select-styled {
            border: 1px solid #bcbcbc;
            padding: 12px 10px;
            &::before {
                content: '';
                border-left: 1px solid #bcbcbc;
                height: 100%;
                position: absolute;
                right: 35px;
                top: 0;
            }
            &::after {
                content: '\f107';
                color: #bcbcbc;
                font-family: 'FontAwesome';
                font-size: 16px;
                margin-top: -3px;
                pointer-events: none;
                position: absolute;
                right: 12px;
                top: 14px;
            }
            &:active,
            &.active {
                &::after {
                    content: "\f106";
                }
            }
        }
        .select-list {
            background-color: @light;
            border: 1px solid #bcbcbc;
            border-top: 0;
            display: none;
            left: 0;
            .listreset;
            max-height: 401px;
            overflow-x: hidden;
            overflow-y: auto;
            position: absolute;
            right: 0;
            top: 100%;
            z-index: 999;
            li {
                color: @dark;
                padding: 10px;
                position: relative;
                .transition;
                &::before {
                    border-top: 1px solid #e5e5e5;
                    content: "";
                    left: 10px;
                    position: absolute;
                    right: 10px;
                    top: 0;
                    .transition;
                }
                &:first-child {
                   &::before {
                        border-top: 0;
                    }
                }
                &:hover {
                    background-color: @blue;
                    color: @light;
                    &::before {
                        border-color: transparent;
                    }
                    & + li {
                        &::before {
                            border-color: transparent;
                        }
                    }
                }
                &[rel="hide"] {
                    display: none;
                }
            }
        }
    }
    .widget_tag_cloud {
        margin: 0 0 27px;
        ul {
            li {
                float: left;
                margin: 0 18px 24px 0;
                a {
                    display: inline-block;
                    height: 35px;
                    width: 47px;
                    background: url(images/blog-tags-sprite.png) no-repeat 1000px 1000px;
                    &.responsive {
                        background-position: 0 0;
                    }
                    &.html {
                        background-position: -65px 0;
                    }
                    &.seo {
                        background-position: -126px 0;
                    }
                    &.ecommerce {
                        background-position: -191px 0;
                    }
                    &.development {
                        background-position: 0 -64px;
                    }
                    &.wordpress {
                        background-position: -65px -64px;
                    }
                    &.mobile {
                        background-position: -129px -64px;
                    }
                    &.printing {
                        background-position: -188px -64px;
                    }
                }
            }
        }
        .tooltip {
            .tooltip-inner {
                background-color: @blue;
                .border-radius(5px);
                font-size: 10px;
                padding: 3px 15px;
                .text-transform-uppercase;
            }
            &.top {
                margin-top: -1px;
                .tooltip-arrow {
                    border-top-color: @blue;
                }
            }
        }
    }
    .widget_photo-gallery {
        ul {
            li {
                margin: 0 0 30px;
                img {
                    border: 3px solid #e1e1e1;
                    width: 100%;
                }
            }
        }
    }
}

/*--------------------------------------------------------------
12.3. Comments
--------------------------------------------------------------*/

.comment-content {
    a {
        word-wrap: break-word;
    }
}
.bypostauthor {
    display: block;
}
.navbar-wrapper {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 20;
    & > .container {
        padding-left: 0;
        padding-right: 0;
    }
    .navbar {
        .container {
            width: auto;
        }
    }
}
.carousel-caption {
    z-index: 10;
}
