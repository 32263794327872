body,
button,
input,
select,
textarea {
    color: @dark;
    font-family: @font-family-sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: @font-family-serif;
    .text-transform-uppercase;
}
h3,
h4,
h5,
h6 {
    clear: both;
    color: @dark;
    margin: 10px 0;
}
h1 {
    font-weight: 400;
    line-height: normal;
    margin: 0;
    padding: 0;
}
h2 {
    color: @light;
    display: inline-block;
    margin: 0 0 18px;
    padding: 13px 20px 0;
    position: relative;
    text-align: center;
    &::before,
    &::after {
        border-color: @light;
        border-style: solid;
        content: "";
        height: 20px;
        position: absolute;
        top: 0;
        width: 20px;
    }
    &::before {
        border-width: 1px 0 0 1px;
        left: 0;
    }
    &::after {
        border-width: 1px 1px 0 0;
        right: 0;
    }
}
.section-title {
    display: inline-block;
    font-size: 35px;
    left: 50%;
    padding: 13px 20px 0;
    position: relative;
    text-align: center;
    .transform(translateX(-50%));
    &::before,
    &::after {
        border-color: @dark;
        border-style: solid;
        content: "";
        height: 20px;
        position: absolute;
        top: 0;
        width: 20px;
    }
    &::before {
        border-width: 1px 0 0 1px;
        left: 0;
    }
    &::after {
        border-width: 1px 1px 0 0;
        right: 0;
    }
}
p {
    margin-bottom: 20px;
    font-size: @font-size-base;
    font-family: @font-family-sans-serif;
}
a[target="_blank"] {
    background-color: transparent;
}
dfn,
cite,
em,
i {
    font-style: normal;
}
blockquote {
    margin: 0 15px;
}
address {
    margin: 0 0 20px;
}
pre {
    font-family: @font-family-sans-serif;
    font-size: @font-size-base;
}
code,
kbd,
tt,
var {
    font-family: @font-family-sans-serif;
    font-size: @font-size-base;
}
abbr,
acronym {
    border-bottom: 1px dotted #666;
    cursor: help;
}
mark,
ins {
    background: @brand-primary;
    text-decoration: none;
}
big {
    font-size: 125%;
}