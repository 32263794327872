@charset "UTF-8";

/*
Theme Name: Virtual Soft
Theme URI: http://www.virtual-soft.com
Author: PressTigers
Author URI: http://www.presstigers.com
Version: 1.0.0
*/

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
1. Bootstrap
2. Colors
3. Normalize
4. Typography
5. Elements
6. Forms
7. Navigation
    7.1. Links
    7.2. Menus
8. Accessibility
9. Alignments
10. Clearings
11. Widgets
12. Content
    12.1. Posts and Pages
    12.2. Asides
    12.3. Comments
13. Infinite Scroll
14. Media
    14.1. Captions
    14.2. Galleries
15. Carousel
--------------------------------------------------------------*/


/*--------------------------------------------------------------
1. Bootstrap
--------------------------------------------------------------*/
@import "../bootstrap/bootstrap.less";

// Custom Mixins
@import "custom-mixins.less";

// Variables
@import "variables.less";

/*--------------------------------------------------------------
2. Colors
--------------------------------------------------------------*/
@import "structure/colors.less";

/*--------------------------------------------------------------
3. Normalize
--------------------------------------------------------------*/
@import "structure/normalize.less";

/*--------------------------------------------------------------
4. Typography
--------------------------------------------------------------*/
@import "structure/typography.less";

/*--------------------------------------------------------------
5. Elements
--------------------------------------------------------------*/
@import "structure/elements.less";

/*--------------------------------------------------------------
6. Forms
--------------------------------------------------------------*/
@import "structure/forms.less";

/*--------------------------------------------------------------
7. Navigation
--------------------------------------------------------------*/
@import "structure/navigation.less";

/*--------------------------------------------------------------
8. Accessibility
--------------------------------------------------------------*/
@import "structure/accessibility.less";

/*--------------------------------------------------------------
9. Alignments
--------------------------------------------------------------*/
@import "structure/alignments.less";

/*--------------------------------------------------------------
10. Clearings
--------------------------------------------------------------*/
@import "structure/clearings.less";

/*--------------------------------------------------------------
11. Widgets
--------------------------------------------------------------*/
@import "structure/widgets.less";

/*--------------------------------------------------------------
12. Content
--------------------------------------------------------------*/
@import "structure/content.less";

/*--------------------------------------------------------------
13. Infinite Scroll
--------------------------------------------------------------*/
@import "structure/infinite-scroll.less";

/*--------------------------------------------------------------
14. Media
--------------------------------------------------------------*/
@import "structure/media.less";

/*--------------------------------------------------------------
15. Carousel
--------------------------------------------------------------*/
@import "structure/carousel.less";

@import "../inline-translation";
@import "../../../backend/less/custom/changelog";
