[aria-describedby="translate-manager-div"] {
  .field-languagetranslate-translation [for="languagetranslate-translation"] {
    display: none;
    &:after {
      content: 'Přeložená hodnota';
    }
  }

  #transslate-manager-change-source-form {
    display: none;
  }

  .translate-manager-message:first-child {
    display: none;
  }

  .ui-widget-header {
    border: none;
    background: none;
  }

  #translate-manager-dialog .translate-manager-message {
    background: none;
    border: none;
    padding: 0;
    margin: 0;

    textarea {
      border: #cdc7c2 1px solid ;
    }

  }

  .ui-dialog-titlebar-close {
    //visibility: hidden;
    margin-top: -25px;
    font-size: 25px;
    margin-right: 8px;
    &:after {
      visibility: visible;
      content: "×";
    }
  }

  .ui-widget-content {
    padding-top: 0;
    border: 0;
  }

  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;

  .ui-dialog-title {
    margin-top: 8px;
  }

  .form-group {
    margin-bottom: 0;
  }

  .ui-dialog-buttonset button:last-child {
    display: none;
  }

}

#toggle-translate {
  display: block !important;
  position: relative !important;
  padding: 15px 30px 15px 15px !important;
  border: 0 !important;
  background: none !important;
  color: #03a9f3 !important;
}

.field-languagesourcesearch-source {
  display: none;
}