.alignleft {
    display: inline;
    float: left;
    margin-right: 15px;
}
.alignright {
    display: inline;
    float: right;
    margin-left: 15px;
}
.aligncenter {
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto;
}