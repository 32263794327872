button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    border: 1px solid @input-border;
    border-radius: @border-radius-base;
    background: @input-bg;
    color: @input-color;
    font-size: @font-size-base;
}
button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover {
    border: 1px solid @input-border-focus;
}
button:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus,
button:active,
input[type="button"]:active,
input[type="reset"]:active,
input[type="submit"]:active {
    border: 1px solid @input-border-focus;
}
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
    color: @input-color;
    border: 1px solid @input-border;
    border-radius: 3px;
    outline: none;
    .appearance;
}
select {
    border: 1px solid @input-border;
}
input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
textarea:focus {
    color: inherit;
}
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"] {
    color: @input-border-focus;
}
input {
    .placeholder;
}
textarea {
    width: 100%;
    .placeholder;
}

/* Select List (Dropdown) */


/* Header Lanuage and Currency Dropdown */
.select-options {
    margin-top: 14px;
    text-align: center;
    @media @desktop {
        margin-left: -49px;
    }
    @media @tablet {
        margin-left: -64px;
    }
    @media @mobile {
        margin-top: 5px;
        text-align: left;
    }
    .currency,
    .language {
        display: inline-block;
        position: relative;
        text-align: left;
    }
    .currency {
        margin-right: 15px;
        width: 55px;
    }
    .language {
        width: 80px;
    }
    .select-hidden {
        display: none;
    }
    .select {
        .appearance;
        background-color: transparent;
        border: 0;
        .box-shadow(none);
        color: @light;
        cursor: pointer;
        display: inline-block;
        font-size: 11px;
        line-height: normal;
        position: relative;
        .text-transform-uppercase;
        vertical-align: top;
        width: 100%;
    }
    .select-styled {
        &::after {
            bottom: 0;
            color: @light;
            content: "\f0d7";
            font-family:'FontAwesome';
            font-size: 16px;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: -1px;

        }
        &:active,
        &.active {
            &::after {
                content: "\f0d8";
            }
        }
    }
    .select-list {
        background-color: @light;
        .box-shadow(0 5px 10px rgba(0, 0, 0, 0.15));
        display: none;
        left: 0;
        .listreset;
        max-height: 401px;
        overflow-x: hidden;
        overflow-y: auto;
        position: absolute;
        right: 0;
        top: 100%;
        z-index: 999;
        li {
            color: @dark;
            padding: 6px 10px;
            .transition;
            &:hover {
                background-color: @blue;
                color: @light;
            }
            &[rel="hide"] {
                display: none;
            }
        }
    }
}

/* Contact Us Form */
.contact-us {
    .form-group {
        margin-bottom: 30px;
    }
    .form-control {
        background-color: transparent;
        border-color: @light;
        .border-radius(0);
        .box-shadow(none);
        color: @light;
        height: 50px;
        padding: 0 10px;
        .placeholder(@color: @light);
        &:focus {
            color: @light;
        }
    }
    textarea {
        &.form-control {
            height: 146px;
            padding: 20px 10px;
            resize: none;
        }
    }
    .btn {
        background-color: @light;
        border-color: @light;
        .border-radius(25px);
        color: @blue;
        .transition;
        width: 176px;
        &:hover {
            background-color: @dark;
            border-color: @dark;
            color: @light;
        }
    }
}

/* Footer Contact Form */
footer {
    .quick-contact {
        @media @mobile {
            clear: both;
            float: left;
            margin-top: 47px;
            width: 100%;
        }
        .form-group {
            margin-bottom: 5px;
            .form-control {
                &:focus {
                    border-color: @blue;
                    box-shadow: none;
                }
            }
        }
        input[type="text"],
        input[type="email"],
        textarea.form-control {
            background-color: transparent;
            border-color: #5d5d5d;
            border-radius: 0;
            color: #aaa;
            font-weight: 300;
            height: 40px;
            padding: 0 20px 2px;
        }
        textarea.form-control {
            padding: 10px 20px;
            height: 100px;
            color: #aaa;
            resize: none;
            &:focus {
                border-color: #5d5d5d;
            }
        }
        input[type="submit"] {
            font-weight: 300;
            margin-top: 5px;
            padding: 6px 10px;
            width: 120px;
            .transition;
            &:hover {
                background-color: @light;
                border-color: @light;
                color: @dark;
            }
        }
    }
}

/* Cart Form */
.woocommerce {
    form {
        p {
            margin-bottom: 0;
        }
    }
    .input-text {
        background: transparent;
        border: 1px solid #e5e5e5;
        .border-radius(0);
        color: #6b6b6b;
        display: block;
        font-size: 14px;
        font-weight: 300;
        height: 38px;
        margin-bottom: 30px;
        padding-left: 10px;
        position: relative;
        width: 100%;
        z-index: 1;
    }
    .woocommerce-checkout {
        .input-text {
            height: 43px;
        }
    }
    .woocommerce-login {
        .input-text {
            height: 43px;
        }
    }
    .woo-select {
        margin-bottom: 30px;
    }
    .select-hidden {
        display: none;
    }
    .select {
        .appearance;
        .box-shadow(none);
        color: #6b6b6b;
        cursor: pointer;
        font-weight: 300;
        height: 38px;
        position: relative;
        width: 100%;
    }
    .select-styled {
        border: 1px solid #e5e5e5;
        padding: 8px 10px;
        &::after {
            content: '\f107';
            color: #6b6b6b;
            font-family: 'FontAwesome';
            font-size: 16px;
            margin-top: -3px;
            pointer-events: none;
            position: absolute;
            right: 8px;
            top: 10px;
        }
        &:active,
        &.active {
            &::after {
                content: "\f106";
            }
        }
    }
    .select-list {
        background-color: @light;
        border: 1px solid #e5e5e5;
        border-top: 0;
        display: none;
        left: 0;
        .listreset;
        max-height: 401px;
        overflow-x: hidden;
        overflow-y: auto;
        position: absolute;
        right: 0;
        top: 100%;
        z-index: 999;
        li {
            color: #6b6b6b;
            padding: 10px;
            position: relative;
            .transition;
            &::before {
                border-top: 1px solid #e5e5e5;
                content: "";
                left: 10px;
                position: absolute;
                right: 10px;
                top: 0;
                .transition;
            }
            &:first-child {
               &::before {
                    border-top: 0;
                } 
            }
            &:hover {
                background-color: @blue;
                color: @light;
                &::before {
                    border-color: transparent;
                }
                & + li {
                    &::before {
                        border-color: transparent;
                    }
                }
            }
            &[rel="hide"] {
                display: none;
            }
        }
    }
    .shop_table {
        td {
            input {
                margin-bottom: 0;
                padding: 0;
            }
        }
    }
}
.input-required {
    position: relative;
    & > label {
        font-weight: 300;
        left: 10px;
        margin: -0.6em 0 0;
        position: absolute;
        top: 50%;
    }
    .hide {
        display: none;
    }
    .first-letter {
	color: #6b6b6b;
    }
    .second-letter {
        color: #f00;
    }
}
