/*--------------------------------------------------------------
7.1. Links
--------------------------------------------------------------*/
a {
    color: @link-color;
    .transition;

    &:hover,
    &:active {
        outline: 0;
    }
    &:focus {
        outline: 0 none;
    }
}
.btn {
    &,
    &:active,
    &.active {
        &:focus,
        &.focus {
            outline: 0 none;
        }
    }
}

/*--------------------------------------------------------------
7.2. Menus
--------------------------------------------------------------*/
/* Header Topbar */
.header-top-bar {
    background-color: @blue;
    height: 50px;
    margin-bottom: 42px;
    padding: 12px 0 0 30px;
    @media @mobile, @tablet, @desktop {
        margin-bottom: 18px;
    }
    @media @tabcustom {
        padding: 13px 0 0 15px;
    }
    a {
        color: @light;
        display: inline-block;
        font-weight: 300;
        text-decoration: none;
        @media @mobile {
            font-size: 13px;
        }
        i {
            color: @light;
            margin-right: 6px;
            .transition;
        }
        &:hover {
            color: @dark;
            i {
                color: @dark;
            }
        }
        &:focus {
            color: @light;
        }
    }
    span {
        color: @light;
        display: inline-block;
        padding: 0 10px;
    }
    &::before {
        background: @blue;
        content: "";
        height: 50px;
        left: -1000%;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: 1000%;
        @media @desktop992-1024 {
            left: -12px;
            width: 12px;
        }
    }
}

/* Header Navigation */
.navbar-nav {
    background-color: @light;
    margin-top: 47px;
    padding-left: 16px;
    width: 784px;
    @media @desktop992-1299 {
        margin-top: 0;
    }
    @media @desktop {
        padding-left: 10px;
        width: 605px;
    }
    &::before {
        background: @light;
        content: "";
        height: 76px;
        position: absolute;
        right: -1000%;
        top: 47px;
        width: 1000%;
        @media @desktop992-1299 {
            top: 0;
        }
        @media @desktop992-1024 {
            right: -28px;
            width: 28px;
        }
    }
    li {
        padding: 0 16.56px;
        @media @desktop {
            padding: 0 7px;
        }
        a {
            font-size: @font-size-large;
            padding: 27px 0 29px;
            .text-transform-uppercase;
        }
        &.dropdown {
            &:hover {
                & > a {
                    color: @blue;
                }
            }
        }
    }
}
.header-inner {
    .header-top-bar {
        margin-bottom: 17px;
    }
    .navbar-nav {
        margin-top: 22px;
        &::before {
            top: 22px;
        }
    }
}
.navbar-header {
    margin-left: 50px;
    @media @tablet {
        margin-left: 5px;
    }
    @media @mobile {
        margin-left: 14px;
    }
}
.navbar-brand {
    padding: 0px;
    @media @mobile {
        height: 70px;
    }

    img {
        width: 100%;
        @media @desktop {
            width: 85%;
        }
        @media @tablet {
            width: 80%;
        }
        @media @mobile {
            width: 70%;
        }
    }
}
.navbar-right {
  	//margin-top: 3px;
    .dropdown-menu {
        left: 0;
    }
    li {
        &:nth-last-child(4){
            .dropdown-menu {
                @media @desktop {
                    left: -40px;
                }
            }
        }
    }
    .mega-dropdown {
        .mega-menu {
            left: auto;
            right: -2px;
        }
    }
}

/* Dropdown Menu */
.dropdown-menu {
    background-clip: border-box;
    background-color: @light;
    .border-radius(0);
    border: 0;
    border-top: 5px solid @blue;
    .box-shadow(0 5px 10px rgba(0, 0, 0, 0.15));
    margin: 0;
    padding: 12px 0 13px;
    width: 270px;
    li {
        background-color: transparent;
        padding: 0;
        .transition;
        a {
            border-top: 1px solid #e5e5e5;
            color: @dark;
            font-size: @font-size-base;
            margin: 0 30px;
            padding: 12px 0;
            text-transform: none;
            &:hover,
            &:focus {
                background-color: transparent;
                color: inherit;
            }
        }
        &:first-child {
            a {
                border-color: transparent;
            }
        }
        &:hover {
            background-color: @blue;
            a {
                border-color: transparent;
                color: @light;
            }
            .sub-menu {
                .opacity(1);
                visibility: visible;
            }
            & + li {
                a {
                    border-color: transparent;
                }
            }
        }
    }
    .sub-menu {
        background-color: @blue;
        left: 100%;
        .opacity(0);
        padding: 12px 0 0;
        position: absolute;
        top: 40px;
        visibility: hidden;
        .transition(@prop: opacity, @time: 0s, @ease: linear);
        li {
            a {
                border-color: @light;
                color: @light;
            }
            &:first-child {
                a {
                    border-color: transparent;
                }
            }
            &:hover {
                background-color: @light;
                a {
                    border-color: transparent;
                    color: @dark;
                }
                & + li {
                    a {
                        border-color: transparent;
                    }
                }
            }
        }
    }
}

/* Mega Menu */
.mega-dropdown {
    position: static !important;
    .mega-menu {
        padding: 0 15px 0 0;
        width: 786px;
        .menu-title {
            font-family: @font-family-serif;
            font-size: 18px;
            font-weight: 700;
            margin: 27px 30px 8px;
            .text-transform-uppercase;
        }
        .nav-pills {
            .listreset;
            li {
                a {
                    .border-radius(0);
                    color: @dark;
                    &:hover {
                        color: @light;
                    }
                    &:focus {
                        background-color: transparent;
                    }
                }
                &.active {
                    a {
                        background-color: transparent;
                        &:hover,
                        &:focus {
                           background-color: transparent;
                        }
                    }
                }
            }
        }
        .tab-description {
            background-color: @blue;
            padding: 27px 30px 50px;
            .tab-title {
                color: @light;
                font-family: @font-family-serif;
                font-size: 18px;
                font-weight: 700;
                margin: 0 0 26px;
                text-align: center;
                .text-transform-uppercase;
            }
            img {
                margin: 0 0 26px;
                width: 100%;
            }
            p {
                color: @light;
                font-weight: 300;
                margin: 0 0 6px;
                a {
                    color: @light;
                    font-size: @font-size-base;
                    font-weight: 700;
                    padding: 0;
                    position: relative;
                    .text-transform-uppercase;
                    &::after {
                        content: "\f101";
                        color: @light;
                        display: block;
                        font-family: 'FontAwesome';
                        font-size: @font-size-large;
                        position: absolute;
                        right: -12px;
                        top: -2px;
                        .transition;
                    }
                    &:hover {
                        color: @dark;
                        &::after {
                            color: @dark;
                        }
                    }
                }
            }
        }
        .quick-links {
            padding: 0 0 0 15px;
            .menu-title {
                margin: 27px 0 8px;
            }
            ul {
                .listreset;
                li {
                    a {
                        display: block;
                        margin: 0;
                        padding-left: 12px;
                        position: relative;
                        &::before {
                            content: "\f105";
                            color: @dark;
                            display: block;
                            font-family: 'FontAwesome';
                            position: absolute;
                            left: 0;
                            .transition;
                        }
                        &:hover {
                            border-color: #e5e5e5;
                            color: @blue;
                            &::before {
                                color: @blue;
                            }
                        }
                    }
                    &:hover {
                        background-color: transparent;
                        & + li {
                            a {
                                border-color: #e5e5e5;
                            }
                        }
                    }
                    &:first-child {
                        a {
                            &:hover {
                                border-color: transparent;
                            }
                        }
                    }
                }
            }
        }
    }
}
.show-box {
    display: block !important;
}

/* Cart Dropdown */
.cart-dropdown {
    background-color: @light;
    border-top: 5px solid @blue;
    clear: both;
    display: none;
    left: auto !important;
    padding: 0 30px 30px;
    right: 15px !important;
    width: 370px;
    ul {
        .listreset;
        li {
            border-bottom: 1px solid #e5e5e5;
            padding: 30px 0;
            img {
                float: left;
                border: 5px solid #e5e5e5;
                .border-radius(50%);
                height: 64px;
                margin-right: 20px;
                width: 64px;
            }
            .item-info {
                float: left;
                margin-top: 7px;
                .name {
                    display: block;
                    margin-bottom: 4px;
                    a {
                        border-color: transparent;
                        color: @dark;
                        font-weight: 300;
                        margin: 0;
                        padding: 0;
                        .text-transform-uppercase;
                        &:hover {
                            color: @blue;
                        }
                    }
                }
                .price {
                    color: @blue;
                    font-weight: 300;
                }
            }
            .remove {
                border: 1px solid #5f5f5f;
                .border-radius(50%);
                float: right;
                height: 24px;
                margin: 20px 0 0;
                padding: 0;
                text-align: center;
                width: 24px;
                i {
                    color: #5f5f5f;
                    font-size: @font-size-small;
                    .transition;
                }
                &:hover {
                    border: 1px solid @blue;
                    i {
                        color: @blue;
                    }
                }
            }
            &:hover {
                background-color: transparent;
            }
        }
    }
    .cart-total {
        color: @dark;
        float: left;
        font-weight: 700;
        margin: 25px 0;
        .text-transform-uppercase;
        width: 100%;
        .title {
            float: left;
        }
        .price {
            float: right;
        }
    }
    .cart-buttons {
        .btn {
            padding: 9px 19px 10px;
            .transition;
        }
        .btn-default {
            float: left;
        }
        .btn-primary {
            color: @light;
            float: right;
        }
    }
}
.cart-dropdown ul li:hover + li .remove {
    border-color: #5f5f5f;
}

/* Header Searchbar */
.header-search {
    clear: both;
    display: none;
    float: right;
    position: relative;
    width: 290px;
    input {
        background-color: @blue;
        border: 0;
        .border-radius(0);
        color: @light;
        font-size: 16px;
        height: 46px;
        padding: 0 90px 1px 20px;
        width: 100%;
        &:focus {
            color: @light;
        }
        .placeholder(@color: @light);
    }
    .btn {
        background-color: @dark;
        border-color: @dark;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        .transition;
        width: 70px;
        &:hover {
            background-color: @gray-base;
            border-color: @gray-base;
            color: @light;
        }
    }
}

/* Breadcrumb */
.breadcrumb {
    background-color: transparent;
    margin: 0;
    padding: 0;
    ul {
        .listreset;
        li {
            color: @blue;
            display: inline-block;
            margin: 0 20px;
            position: relative;
            .text-transform-uppercase;
            &::after {
                color: @light;
                content: "|";
                position: absolute;
                right: -26px;
                top: -1px;
            }
            &:last-child {
                &::after {
                    content: "";
                }
            }
            a {
                color: @light;
                &:hover {
                    color: @blue;
                }
            }
        }
    }
}

/* Mobile Menu */
.mm-menu.mm-right.mm-front,
.mm-menu.mm-right.mm-next {
    @media @mobile {
        right: -100%;
    }
}
.mm-menu.mm-right {
    @media @mobile {
        width: 100%;
    }
}
#mobile-menu:not(.mm-menu) {
    display: none;
}
#mobile-menu {
    a {
        .transition(none);
    }
}
.mobile-menu {
    right: 15px;
    position: absolute;
    top: 0;
    z-index: 99999;
    @media @tablet {
        right: 5px;
    }
    a {
        i {
            color: @light;
            font-size: 30px;
        }
    }
}
.mm-list {
    & > li {
        h4 {
            color: @light;
            display: block;
            font-size: @font-size-h3;
            margin: 17px 20px 16px;
            z-index: -1;
        }
        .close {
            background: url(../images/close_icon.png) no-repeat;
            border: 0px;
            cursor: pointer;
            float: right;
            height: 15px;
            padding: 0;
            margin: -35px 20px 0 0;
            .opacity(1);
            width: 15px;
        }
        &:hover {
            background-color: rgba(28, 28, 28, .5);
        }
        &.mobile-title {
            &:hover {
                background-color: transparent;
            }
        }
    }
}
.mm-list > li.mm-selected > a.close:not(.mm-subopen) {
    background: url(../images/close_icon.png) no-repeat;
}
.mobile-search {
    position: relative;
    input {
        background-color: @gray-base;
        border: 0;
        .border-radius(0);
        color: @light;
        font-size: 16px;
        height: 46px;
        padding: 0 90px 1px 20px;
        width: 100%;
        &:focus {
            color: @light;
        }
        .placeholder(@color: @light);
    }
    .btn {
        background-color: @blue;
        border-color: @blue;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        .transition;
        width: 70px;
        &:hover {
            background-color: @light;
            border-color: @light;
            color: @blue;
        }
    }
}
