@light:         #fff;
@dark:          #2f2f2f;
@yellow:        #f9b81e;
@yellow-light:  #ffcc27;
@blue:          #00adef;
@blue-light:    #50b6de;
@green:         #add747;
@dark-green:    #43c5b9;
@red:           #f56363;
@magenta:       #c644fc;
@silver:        #babec5;
@orange-light:  #f2b375;

/* Colors */
.c-primary {
    color: @brand-primary;
}
.c-success {
    color: @brand-success;
}
.c-info {
    color: @brand-info;
}
.c-warning {
    color: @brand-warning;
}
.c-danger {
    color: @brand-danger;
}
.c-light {
    color: @light;
}
.c-dark {
    color: @dark;
}
.c-yellow {
    color: @yellow;
}
.c-blue {
    color: @blue;
}
.c-green {
    color: @green;
}
.c-dark-green {
    color: @dark-green;
}
.c-red {
    color: @red;
}
.c-magenta {
    color: @magenta;
}

/* Background Colors */
.bg-primary {
    background-color: @brand-primary;
}
.bg-success {
    background-color: @brand-success;
}
.bg-info {
    background-color: @brand-info;
}
.bg-warning {
    background-color: @brand-warning;
}
.bg-danger {
    background-color: @brand-danger;
}
.bg-light {
    background-color: @light;
}
.bg-dark {
    background-color: @dark;
}
.bg-yellow {
    background-color: @yellow;
}
.bg-blue {
    background-color: @blue;
}
.bg-green {
    background-color: @green;
}
.bg-dark-green {
    background-color: @dark-green;
}
.bg-red {
    background-color: @red;
}
.bg-magenta {
    background-color: @magenta;
}

/* Borders */
.b-primary {
    border: solid 1px @brand-primary;
}
.b-success {
    border: solid 1px @brand-success;
}
.b-info {
    border: solid 1px @brand-info;
}
.b-warning {
    border: solid 1px @brand-warning;
}
.b-danger {
    border: solid 1px @brand-danger;
}
.b-light {
    border: solid 1px @light;
}
.b-dark {
    border: solid 1px @dark;
}
.b-yellow {
    border: solid 1px @yellow;
}
.b-blue {
    border: solid 1px @blue;
}
.b-green {
    border: solid 1px @green;
}
.b-dark-green {
    border: solid 1px @dark-green;
}
.b-red {
    border: solid 1px @red;
}
.b-magenta {
    border: solid 1px @magenta;
}