.changelog {

  .changelog-deploy {
    padding-left: 15px;
    margin-top: 3px;
    border-left: 2px #f1f2f7 solid;
  }

  .changelog-entry {
    margin-top: 15px;
    display: flex;
    align-items: center;
  }

  .changelog-type {
    min-width: 60px;
  }

  .changelog-message {
    margin-left: 10px;
  }
}

.changelog-frontend {
  .changelog-date {
    margin-top: 45px;
  }
}